<!-- (onRowSelect)="rowClick.emit(selected)" -->
<div [ngStyle]="{display: visible ? 'block' : 'none'}">
    <p-treetable #pTable *ngIf="filtering.value"
             [value]="transformToTreeNodes(filtering.value)"
             [rows]="20"
             [paginator]="!hidePaginator"
             [first]="first || 0"
             [resetPageOnSort]="true"
             [selectionMode]="selectionMode || 'single'"
             [(selection)]="selectedNode"
             (onNodeSelect)="nodeSelect($event)"
             (onSort)="customSort($event)"
    
             >
        <ng-template pTemplate="caption">
            <div class="d-flex justify-content-between">
                <div style="text-align: left; width: 100%;" class="d-flex justify-content-between">
                    <!-- LEFT -->
                    <div class="d-flex justify-content-start align-items-center">
                        <div *ngIf="request.isLoading" style="
                            display: inline-block;
                            margin-right: 10px;
                            position: relative;
                            top: 3px;">
                            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6"
                                               animationDuration="1.5s">
                            </p-progressSpinner>
                        </div>

                        <!-- Barra de búsqueda -->
                        <div *ngIf="!hideSearch">
                            <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
                        </div>

                        <div *ngIf="!hideSearch" class="searchbar-container">
                            <input id="common-list-search-text"
                                   #searchText
                                   [(ngModel)]="searchValue.term"
                                   pInputText
                                   (input)="setGlobalContentFilter($event.target)"
                                   type="text"
                                   size="large"
                                   placeholder="Buscar..."
                                   (keyup)="setSearchText(searchText.value);">
                        </div>

                        <!-- Botón "Exportar a CSV" -->
                        <div *ngIf="showExportCsv">
                            <button class="mx-2 btn"
                                (click)="exportCsv()"
                                style="padding: 5px; font-size: .8rem; background-color: #27A745; color: white; width: 8rem;">
                                <i class="fa fa-download"></i>
                                Exportar a CSV                            
                            </button>
                        </div>
                        <div *ngIf="customExportCsv">
                            <button class="mx-2 btn"
                                (click)="exportCsv()"
                                style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white; width: 8rem;">
                                <i class="fa fa-download"></i>
                                Exportar listado                            
                            </button>
                        </div>

                      

                        <div>
                            <button *ngIf="enableFilter" class="btn btn-outline-dark"
                                    style="font-size: 0.8em;margin-left: 8px;"
                                    (click)="filterPressed.emit()">
                                <i class="fa fa-filter" style="margin:4px 4px 0 0"></i>
                                Filtros
                            </button>
                        </div>
                    </div>

                    <!-- CENTER -->
                    <div style="width: 100%; align-content: center;">
                          <!-- Botón "RETO" -->
<!--                           <div style="text-align: center;">
                            <button class="mx-2 btn btn-info" *ngIf="showStockReto"
                                (click)="exportReto()"
                                style="padding: 5px; font-size: .8rem;">
                                <i class="fa fa-download"></i>
                                Exportar RETO                            
                            </button>
                        </div> -->
                        <ng-template *ngIf="header" ngFor let-col [ngForOf]="[cols]"
                                     [ngForTemplate]="header"></ng-template>
                    </div> 

                    <!-- CENTER RIGHT -->

                    <div *ngIf="hasEditableFields && (editButtonsPosition === 'top')">
                        <div class="d-flex justify-content-center align-items-center">
                            <button class="btn  btn-primary"
                                (click)="saveButtonClick.emit()"
                                style="border-radius: 3px; margin-left: 10px;">
                                <i class="fa fa-save"></i>
                            </button>
                            <button class="btn  btn-primary"
                                (click)="undoButtonClick.emit()"
                                style="border-radius: 3px; margin-left: 10px;">
                                <i class="fa fa-undo"></i>
                            </button>
                        </div>
                    </div>

                    <!-- RIGHT -->
                    <div class="d-flex align-items-center text-left" *ngIf="actionButtonText && this.rol !== 'gerencia' && this.rol !== 'aplicador'">
                        <div>
                            <span *ngIf="request.hasError" style="margin-right: 10px" tooltipPosition="bottom"
                                pTooltip="Ha ocurrido un error...">
                                <i class="fa fa-exclamation-triangle" style="color: black"></i>
                            </span>
                        </div>
                        <div *ngIf="showAddButton()">
                            <button (click)="actionButtonClick.emit()" class="btn" style="padding: 10px;width: 9rem;background-color: #27A745;color:white;
                                                       font-size: .8em">
                                {{actionButtonText}}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <ng-template *ngIf="caption" ngFor let-col [ngForOf]="[cols]" [ngForTemplate]="caption"></ng-template>
            </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of cols" [style.width]="col.width">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr class="small">
                <ng-template *ngIf="thStart" ngFor let-col [ngForOf]="[cols]" [ngForTemplate]="thStart"></ng-template>
                <th *ngFor="let col of cols" [ttSortableColumn]="col.field">
                    <div class="d-flex justify-content-center align-items-center min-height-40"
                         *ngIf="!col.filter">
                        {{col.header}}
                        <p-treeTableSortIcon *ngIf="col.sort !== false" [field]="col.field" class="ms-2"/>
                    </div>

                    <div *ngIf="col.filter">
                        {{col.header}}
                        <div [ngSwitch]="col.filter">
                            <p-select *ngSwitchCase="'dropdown'" [options]="dropdown[col.field]"
                                        [style]="{'width':'100%', 'fontSize': '0.7em'}"
                                        (onChange)="setDropdownSelectOn($event.value,col.field)">
                            </p-select>

                            <p-multiSelect *ngSwitchCase="'multiselect'" [options]="multiselect[col.field]"
                                           [(ngModel)]="filters.multiselect[col.field]"
                                           [style]="{'width':'100%', 'fontSize': '0.7em'}"
                                           (onChange)="setMultiSelectOn($event.value, col.field)">
                            </p-multiSelect>

                            <p-datePicker *ngSwitchCase="'calendar'" [(ngModel)]="filters.dateRange[col.field]"
                                        (onClose)="setDateRangeOn()"
                                        (onClearClick)="cleanDateRangeOn(col.field)"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2005:2030"
                                        showButtonBar="true" selectionMode="range"
                                        readonlyInput="true">
                            </p-datePicker>
                        </div>
                    </div>
                </th>

                <th *ngIf="hasEditableFields && editButtonsPosition === 'tableEnd'" style="width: 80px">
                    <div class="d-flex justify-content-center align-items-center min-height-40">
                        <span>Acciones</span>
                    </div>
                </th>
                <ng-template *ngIf="thEnd" ngFor let-col [ngForOf]="[cols]" [ngForTemplate]="thEnd"></ng-template>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr class="base-row-style"
                [ttRow]="data"
                [ttSelectableRow]="data"
                [ngStyle]="processRowConditionalStyle(data.node)">
                <ng-template *ngIf="tdStart" ngFor let-data [ngForOf]="[data.node]" [ngForTemplate]="tdStart"></ng-template>

                <td *ngFor="let col of cols"
                    class="ui-resizable-column"
                    [ngStyle]="processCellConditionalStyle(data.node, col)"
                    pEditableColumn>

                    <div *ngIf="col.editable" class="edit-field">
                        <p-treeTableCellEditor>
                            <ng-template pTemplate="input">
                                <input class="input-edit-field" type="text" [(ngModel)]="data.node[col.field]" [maxLength]="col.maxLength"
                                    (blur)="editField($event, data.node, col.field)">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{processCellVisualTransform(data.node, col)}}
                            </ng-template>
                        </p-treeTableCellEditor>
                    </div>

                    <div *ngIf="!col.editable" style="
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;">
                        {{processCellVisualTransform(data.node, col)}}
                    </div>
                </td>

                <td *ngIf="hasEditableFields && editButtonsPosition === 'tableEnd'" style="width: 80px">
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center"
                            (click)="saveButtonClick.emit()"
                            style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007bff;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-save"></i>
                        </button>
                        <button class="d-flex justify-content-center align-items-center"
                            (click)="undoButtonClick.emit()"
                            style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007bff;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-undo"></i>
                        </button>
                    </div>
                </td>

                <ng-template *ngIf="tdEnd" ngFor let-data [ngForOf]="[data.node]" [ngForTemplate]="tdEnd"></ng-template>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer" *ngIf="footer">
            <tr class="base-row-style">
                <td *ngIf="thStart" style="font-size: 0em">
                    <ng-template *ngIf="thStart" ngFor let-col [ngForOf]="[cols]"
                                 [ngForTemplate]="thStart"></ng-template>
                </td>

                <td *ngFor="let col of cols">
                    <ng-template *ngIf="footer" ngFor let-col [ngForOf]="[col]" [ngForTemplate]="footer"></ng-template>
                </td>
                <td *ngIf="thEnd" style="font-size: 0em">
                    <ng-template *ngIf="thEnd" ngFor let-col [ngForOf]="[cols]" [ngForTemplate]="thEnd"></ng-template>
                </td>
            </tr>
        </ng-template>
    </p-treetable>
    <br>
</div>

<a href="#" id="csvLink" style="visibility: hidden; position: fixed;"></a>
