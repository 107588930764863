<br>
<app-common-list-table
  [request]="getRequest"
  [filtering]="filter"
  [cols]="cols"
  [globalVisualTransform]="globalVisualTransform"
  actionButtonText="Añadir Tratamiento"
  (actionButtonClick)="add()">

  <ng-template #header>
    <div *ngIf="currentUser?.rol === 'admin'" class="d-flex justify-content-start align-content-center">
      <p-inputSwitch
        [style]="{marginLeft: '15px', marginRight: '10px'}"
        [(ngModel)]="showAll"
        (onChange)="changeShowAll();filter ? filter.filter() : ''">
      </p-inputSwitch>
      <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todos</p>
    </div>
</ng-template>

  <ng-template #thEnd>
    <th>
      <p class="d-flex justify-content-center mb-0">Acciones</p>
    </th>
    <th style="width: 90px;">
      <p class="mb-0 d-flex justify-content-center">Prescripción</p>
    </th>
    <th style="width: 50px;">
      <p class="mb-0 d-flex justify-content-center">Sistronic</p>
    </th>
  </ng-template>

  <ng-template #tdEnd let-data>
    <td>
      <app-action-buttons (showRowButtonEvent)="show('info', data.data)" (editRowButtonEvent)="edit(data.data)" (deleteRowButtonEvent)="show('delete', data.data)"
        [showDuplicate]="true" (duplicateRowButtonEvent)="duplicate(data.data)">
      </app-action-buttons>
    </td>
    <td>
      <div class="d-flex justify-content-center align-items-center">
        <button class="d-flex justify-content-center align-items-center"
              (click)="generarReceta(data.data)"
              style="
                height: 20px;
                width: 20px;
                border-radius: 3px;
                background-color: #007b00;
                border: none;
                margin-right: 8px;
                color: white;
                cursor: pointer;">
            <i class="fa fa-file-pdf-o"></i>
        </button>
      </div>
   </td>
    <td>
      <div class="d-flex justify-content-center align-items-center">
        <button class="d-flex justify-content-center align-items-center"
              (click)="loadResumeTronic(data.data)"
              style="
                height: 20px;
                width: 20px;
                border-radius: 3px;
                background-color: #007bff;
                border: none;
                color: white;
                cursor: pointer;">
            <i class="fa fa-eye"></i>
        </button>
      </div>
      
    </td>
  </ng-template>

</app-common-list-table>

<p-dialog *ngIf="canShow['info']" [header]="title_modal" [(visible)]="canShow['info']" (onShow)="scrollToTop()">

  <div style="width: 80vw !important">

    <div class="d-flex flex-wrap justify-content-between">
      <div style="flex: 2" class="mr-2">
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [model]="model">
        </app-form-builder>
      </div>

      <div style="flex: 1">
        <table *ngIf="model?.ids_productos" class="table-style">
          <tr>
            <th style="width: 15px; border: none"></th>
            <th>Producto</th>
            <th>Dosis&nbsp;(%)</th>
          </tr>
          <tr *ngFor="let item of model?.cantidades_list; let i = index">
            <td style="border: none; background: #f8f9fa !important; color: black !important;">
              {{i + 1}}
            </td>
            <td>
              {{ model.productos_list?.[i] }}
            </td>
            <td>
              {{ decimalFormat( +(model.cantidades_list?.[i] || 0),2) }}&nbsp;{{  model.productos_uds?.[i]}}
            </td>
          </tr>
        </table>
        <div *ngIf="!model?.ids_productos" class="alert alert-light small">
          <i class="fa fa-exclamation-triangle mr-1"></i>
          Este tratamiento no tiene productos
        </div>
      </div>
    </div>

    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>
</p-dialog>

<p-dialog 
  *ngIf="canShow['delete']"
  header="Eliminar registro" 
  [(visible)]="canShow['delete']" 
  (onShow)="scrollToTop()"
>

  <div style="width: 80vw !important">
    <app-form-builder [fields]="formFields" [distributionColumns]="3" [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button [warning]="deleteWarning" (click)="deleteRegister()" [request]="deleteRequest"
        [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading" style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>
</p-dialog>

<p-dialog header="Generar receta" [(visible)]="showReceta" (onShow)="scrollToTop()">
  <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
    <div id="html2canvasDiv" class="p-2">
      <!-- Otros logos -->
      <div style="max-width: 100px">
        <img src="assets/logo_manezylozano.png" style="width: 100%; height: auto" alt="Logo"/>
      </div>
      <div class="my-4 d-flex justify-content-between">
        <span style="flex: 1;">Titular: {{usuario.nombre}} {{usuario.apellidos}}</span>
        <b style="flex: 1;">Tratamiento: <u>{{receta.nombre}}</u></b>
        <span style="flex: 1;">Fecha: {{receta.fecha}}</span>
      </div>
      <div class="my-4 d-flex justify-content-between">
        <span style="flex: 1;">Nº Tratamiento: {{receta.n_tratamiento}}</span>
        <span style="flex: 1;">Campo: {{receta.campo_nombre}}
          <b>({{receta.campo?.superficie_cultivada || 0}} {{unidad || 'unidad'}})</b>
        </span>
        <span style="flex: 1;" class="text-white">.</span>
      </div>

      <p class="mb-0">Productos utilizados</p>
      <div class="mb-3">
        <table class="registro-table table-bordered">
          <thead class="font-weight-bold">
            <th>Producto</th>
            <th>Materia&nbsp;Activa</th>
            <th>Dosis (%)</th>
            <th>Dosis (l/{{unidad || 'unidad'}})</th>
            <th>Total producto</th>
            <th>X&nbsp;Máquina (dosis x total)</th>
          </thead>
          <tr *ngFor="let producto of receta.productos; let i = index">
            <td>{{producto?.nombre}}</td>
            <td>{{producto?.materia_activa}}</td>
            <td>{{producto?.dosis || '0,00'}}</td>
            <td>{{producto?.dosisTotalProducto || '0,00'}}</td>
            <td>{{producto?.total || '0,00'}}</td>
            <td>{{producto?.dosisTotal || '0,00'}}</td>
          </tr>
        </table>
      </div>

      <p class="mb-0">Datos del tractor</p>
      <div class="mb-3">
        <table class="registro-table table-bordered">
          <thead>
            <th style="width: 20%;">Nombre</th>
            <th style="width: 20%;">Litros/{{unidad || 'unidad'}}</th>
            <th style="width: 10%;">Velocidad (Km/h)</th>
            <th style="width: 10%;">RPM</th>
            <th style="width: 10%;">Presión (BAR)</th>
            <th style="width: 10%;">Boquillas</th>
          </thead>
          <tr>
            <td class="text-left">{{receta.maquinaria_nombre}}</td>
<!--             <td>{{aux_data?.litros}} ({{receta?.litros}})</td>
            <td>{{aux_data?.marcha}} ({{receta?.marcha}})</td>
            <td>{{aux_data?.rpm}} ({{receta?.rpm}})</td>
            <td>{{aux_data?.presion}} ({{receta?.presion}})</td>  -->
            <td>{{ decimalFormat(receta.end_litros,2) }} ({{decimalFormat (receta.litros,2)}})</td>
            <td>{{ decimalFormat( receta.end_marcha,2)}} ({{decimalFormat (receta.marcha,2)}})</td>
            <td>{{ decimalFormat( receta.end_rpm,2)}} ({{decimalFormat (receta.rpm,2)}})</td>
            <td>{{ decimalFormat( receta.end_presion,2)}} ({{ decimalFormat(receta.presion,2)}})</td>
            <td>{{receta.boquilla?.nombre}}</td>
          </tr>
        </table>
      </div>
      <div class="mb-3">
        <table class="registro-table table-bordered">
          <thead>
            <th>Modo trabajo</th>
            <th>Regulación dosis</th>
            <th>Estado tratamiento</th>
       
          </thead>
          <tbody>
            <tr>
              <td>{{receta.modo_trabajo}} </td>
              <td>{{aux_data.regulacion_dosis}} ({{receta.regulacion_dosis}})</td>
              <td *ngIf="!aux_data" >{{receta.estado_tratamiento }}</td>
              <td *ngIf="aux_data" >{{aux_data.estado_tratamiento }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-3">
        <table class="registro-table table-bordered">
          <thead>
            <th>CAUDAL</th>
            <th>TIEMPO TRATAMIENTO</th>
            <th>LITROS TOTALES</th>
          </thead>
          <tbody>
            <tr>
              <td>{{receta.caudal}} l/min</td>
              <td>{{receta.tiempo || '-:--'}}</td>
              <td>{{receta.litros_totales}} lts</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-3 text-center">
        <table class="registro-table table-bordered">
          <tr>
            <td class="font-weight-bold" style="width: 160px;">PRESIÓN (BAR)</td>
            <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
              {{i}}
            </td>
          </tr>
          <tr class="font-weight-bold">
            <td>Caudal (l/min)</td>
            <td *ngFor="let i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
              {{totalCaudales[i] || 0.00}}
            </td>
          </tr>
        </table>
      </div>

    </div>

    <div class="d-flex justify-content-center">

      <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
      <button class="btn btn-primary mx-2" (click)="imprimirReceta();">Imprimir</button>

      <button *ngIf="showEnviarCorreo"
      (click)="enviarCorreo()"
      class="btn mx-2"
      [ngClass]="tratamientosApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'"
      tooltipPosition="top" pTooltip="Se enviará la receta a este email">

      <ng-container *ngIf="!tratamientosApi.enviarRecetaCaptura.isLoading && !tratamientosApi.enviarRecetaCaptura.hasError">
          <i class="fa fa-envelope"></i>
          <span *ngIf="isGeneratingCanvas">
              Generando pdf...
          </span>
          <span *ngIf="!isGeneratingCanvas && receta?.usuario_email">
              Enviar a {{receta.usuario_email }}
          </span>
          <span *ngIf="!isGeneratingCanvas && !(receta?.usuario_email)">
              Enviar email
          </span>
      </ng-container>
      <ng-container *ngIf="tratamientosApi.enviarRecetaCaptura.isLoading && !tratamientosApi.enviarRecetaCaptura.hasError">
          <i class="fa fa-envelope"></i>
          Enviando...
      </ng-container>
      <ng-container *ngIf="tratamientosApi.enviarRecetaCaptura.hasError">
          <i class="fa fa-exclamation-triangle"></i>
          {{tratamientosApi.enviarRecetaCaptura.error}}
      </ng-container>
      </button>


    </div>

  </div>
</p-dialog>

<a id="downloadReceta" href="#" style="visibility: hidden; position: fixed;"></a>
<p-dialog 
[header]="title_modal" 
[(visible)]="showGraficas" 
(onShow)="scrollToTop()" 
[contentStyle]="{'max-height':'80vh'}"
(onHide)="deleteInterval()" 
>

  <div style="width: 80vw !important" *ngIf="showGraficas">
    <div class="d-flex flex-wrap justify-content-between">
      <div style="flex: 2" class="mr-2">
        <div class="row">
          <div class="col-4">
            <label for=""><span style="font-weight: bold;">Tratamiento:</span> {{data.n_tratamiento}}</label>
          </div>
          <div class="col-4">
            <label for=""><span style="font-weight: bold;">Nombre:</span> {{data.nombre}}</label>
          </div>
          <div class="col-4">
            <label for=""><span style="font-weight: bold;">Fecha receta:</span> {{data.fecha_formatted}}</label>
          </div>
          <div class="col-4">
            <label for=""><span style="font-weight: bold;">Campo:</span> {{data.campo_nombre}}</label>
          </div>
          <div class="col-4">
            <label for=""><span style="font-weight: bold;">Maquinaria</span> {{data.maquinaria_nombre}}</label>
          </div>
          <div class="col-4" style="display: flex;">
           <button style="margin-left: 10px; background: none; border: none" (click)="hiddenShowSpeed()" pTooltip="Velocidad (km/h)/Presión (bar)" tooltipPosition="top">
             <img src="./assets/images/chart/speed.png" alt="" 
             [ngClass]="showSpeed ? 'button-display-charts' : 'button-display-charts-focus'"
             class="button-display-charts">
           </button>
          <!--  <div style="margin-left: 10px" (click)="hiddenShowPressure()" pTooltip="Presión (bar)" tooltipPosition="top">
             <img src="assets/images/chart/pressure.png" alt="" 
             [ngClass]="showPressure ? 'button-display-charts' : 'button-display-charts-focus'">
           </div>
           <div style="margin-left: 10px" (click)="hiddenShowFlow()" pTooltip="Caudal (l/min)" tooltipPosition="top">
             <img src="assets/images/chart/flow.png" alt="" 
             [ngClass]="showFlow ? 'button-display-charts' : 'button-display-charts-focus'">
           </div>
           <div style="margin-left: 10px" (click)="hiddenShowDose()" pTooltip="Dosis aplicada" tooltipPosition="top">
             <img src="assets/images/chart/dose.png" alt="" 
             [ngClass]="showFlow ? 'button-display-charts' : 'button-display-charts-focus'">
           </div> -->
          <!--  <div style="margin-left: 10px" (click)="hiddenShowSectionRoute()" pTooltip="Ruta" tooltipPosition="top">
             <img src="assets/images/chart/maps_route.png" alt="" 
             [ngClass]="showMapRoute ? 'button-display-charts' : 'button-display-charts-focus'">
           </div> -->
          </div>
        </div>
      
        
       <br>

      </div>
    </div>
    <div class="row">
      <div class="col-12" style="margin-top: 20px;" *ngIf="showSpeed">
        <app-grafica-velocidad-manez-lozano 
        [grafica_vel]="grafica_vel"
        [grafica_pressure]="grafica_pressure" 
        [grafica_rpm]= "grafica_rpm" 
        [grafica_flow]= "grafica_flow" 
        [grafica_dose]= "grafica_dose" 
        *ngIf="grafica_vel">
      </app-grafica-velocidad-manez-lozano>
      </div>
      <!-- <div class="col-lg-6 col-md-12 col-sm-12" style="margin-top: 20px;" *ngIf="showPressure">
        <app-grafica-presion-manez-lozano  [grafica_pressure]="grafica_pressure" *ngIf="grafica_pressure"></app-grafica-presion-manez-lozano>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12"  style="margin-top: 80px;"  *ngIf="showFlow">
        <app-grafica-caudal-manez-lozano [graficacaudal]="grafica_flow" *ngIf="grafica_flow"></app-grafica-caudal-manez-lozano>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12"  style="margin-top: 80px;" *ngIf="showDose">
        <app-grafica-dose-manez-lozano [dataDoseUSer]="data_dose_user" [graficaDose]="grafica_dose" *ngIf="grafica_dose"></app-grafica-dose-manez-lozano>
      </div> -->
      <div class="col-12" *ngIf="showGraficas">
     <!--      {{showGraficas}}
          {{showMapDirections}}
          {{showMap}} -->
        <div *ngIf="showMap" style="margin-top: 77px;" >
         
          <div style="display: flex; gap: 20px;justify-content: space-between;">
            <div>
              <h4>Recorrido</h4>
            </div>
            <!-- [ngStyle]="{'pointer-events': showMapDirections ? '': 'none'}" -->
            <div style="position: relative; bottom: 10px;" >
              <button style="margin: 10px" 
              [ngClass]="updateMap ? 'button-refresh-active ' : ' button-refresh '" 
              (click)="routeReDraw()" pTooltip="Actualizar" tooltipPosition="top">
                <i class="fa fa-refresh " style="padding: 5px;"
                [ngClass]="updateMap ? 'fa fa-refresh fa-spin ' : ' fa fa-refresh'" 
                aria-hidden="true">
              </i>
           
              </button>
            </div>
          </div>
          <div #gmap class="resp-iframe map-active" style="min-height: 700px !important;">
          </div>
        </div>
      </div>
    </div>
   
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)=" showGraficas = false; ">Cerrar</button>
    </div>
  </div>
  <br>
</p-dialog>