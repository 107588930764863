<div style="max-width: auto; overflow: auto;">
    <div #passport>
    </div>
    <app-common-list-table
        [showExportCsv]="this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest'"
        [request]="getRequest" 
        [cols]="cols" 
        [filtering]="filtering"
        [globalConditionalStyle]="globalConditionalStyle" 
        [globalVisualTransform]="globalVisualTransform"
        [customExportCsv]="showTareasBioExportCsv"
        [actionButtonText]="canAddTareas ? btnAnadir : ''"
        (emitSearchText)="updateFooter()"
        (actionButtonClick)="add()">

        <ng-template #header>
            <div class="d-flex justify-content-start">
                <div *ngIf="this.rolUser ==='responsable' || this.appName === 'benibal'" style="margin-left: 20px;">
                    <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                        <i class="fa fa-download"></i>
                        Exportar listado
                    </button>
                </div>
                <div *ngIf="showAplicados" style="width: 100%; margin-right: 15px"
                    class="d-flex justify-content-start align-items-center">
                    <div *ngIf="this.appName === 'hernandorena'" style="margin-right: 15px"
                        class="d-flex justify-content-start align-content-center">
                        <p-inputSwitch [style]="{marginLeft: '15px', marginRight: '10px'}" [(ngModel)]="showAllTareas"
                            (onChange)="changeShowAll()">
                        </p-inputSwitch>
                        <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todas</p>
                    </div>
                    <div style="margin-left: 10px; margin-right: 10px">
                        <div style="font-size: 0.7em" class="btn-group">
                            <button (click)="filtrarTratamientos('aplicados', 'switch_aplicados')"
                                [ngClass]="aplicado.selected === 'aplicados' ? 'btn btn-primary' : 'btn btn-light'" style="
                                    height: 28px;
                                    width: 40px;
                                    border: 1px solid grey;
                                    border-right: 1px solid grey">
                                SÍ
                            </button>
                            <button (click)="filtrarTratamientos('no-aplicados', 'switch_aplicados')"
                                [ngClass]="aplicado.selected === 'no-aplicados' ? 'btn btn-primary' : 'btn btn-light'"
                                style="
                                    height: 28px;
                                    width: 40px;
                                    border: 1px solid grey;
                                    border-right: 1px solid grey">
                                NO
                            </button>
                        </div>
                    </div>
                    <span *ngIf="mostrarTratamientosAplicados" style="color: white; margin-top: -0px;">
                        Aplicados
                    </span>
                    <div *ngIf="showCampaign" id="campaign">
                        <label for="" style="margin-left: 50px;">Campaña</label>
                        <p-select [options]="years" [(ngModel)]="selectedYears" (onChange)='selectedCampaign()'>
                        </p-select>
                    </div>
                    <div style="margin-left: 20px ;display: flex;" *ngIf="betweenDates">
                        <app-datepicker (sendFecha)="getFechaInicio($event)">
                        </app-datepicker>
                        <p style="color:white; margin-left: 10px;margin-bottom: 0;">F.Inicio</p>
                    </div>
                    <div style="margin-left: 20px ;display: flex;" *ngIf="betweenDates">
                        <app-datepicker (sendFecha)="getFechaFin($event)">
                        </app-datepicker>
                        <p style="color:white; margin-left: 10px;margin-bottom: 0;">F.Fin</p>
                    </div>
                </div>                
            </div>

            <div *ngIf="showFitoValidado && (currentUser.rol === 'admin')" style="width: 100%; margin-right: 15px"
                class="d-flex justify-content-start align-items-center">
                <div style="margin-left: 10px; margin-right: 10px">
                    <div style="font-size: 0.7em" class="btn-group">
                        <button (click)="filtrarTratamientos('validados', 'switch_validados')"
                            [ngClass]="validado.selected === 'validados' ? 'btn btn-primary' : 'btn btn-light'" style="
                                height: 28px;
                                width: 40px;
                                border: 1px solid grey;
                                border-right: 1px solid grey">
                            SÍ
                        </button>
                        <button (click)="filtrarTratamientos('no-validados', 'switch_validados')"
                            [ngClass]="validado.selected === 'no-validados' ? 'btn btn-primary' : 'btn btn-light'"
                            style="
                                height: 28px;
                                width: 40px;
                                border: 1px solid grey;
                                border-right: 1px solid grey">
                            NO
                        </button>
                    </div>
                </div>
                <span *ngIf="mostrarTratamientosAplicados" style="color: white; margin-top: -0px;">
                    Validados
                </span>
            </div>

            <div *ngIf="showBtnTratamiento" style="position: absolute; right: 96px; top: 4px;">
                <button (click)="createNavigation('tratamientos')" class="btn btn-primary" style="padding: 5px;
                   font-size: .8em; margin-top: 0.5rem;">
                    Tratamientos
                </button>
            </div>
        </ng-template>

        <ng-template #thEnd>
            <th>
                <p class="mb-0 d-flex justify-content-center">Acciones</p>
            </th>
            <th style="width: 100px;">
                <p class="mb-0 d-flex justify-content-center">Prescripción</p>
            </th>
            <th *ngIf="showFitoValidado">
                <p class="mb-0 d-flex justify-content-center">Validado</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons [hideDelete]="hiddenButtonDelete" [showDuplicate]="canDuplicateTareas"
                    (duplicateRowButtonEvent)="duplicate(data.data)" (editRowButtonEvent)="edit(data.data)" (deleteRowButtonEvent)="show('delete', data.data)"
                    (showRowButtonEvent)="show('info', data.data)">
                </app-action-buttons>
            </td>
            <td>
                <div class="d-flex justify-content-center">
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center" (click)="generarReceta(data.data)"
                            tooltipPosition="top" pTooltip="Receta" style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007b00;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-file-pdf-o"></i>
                        </button>
                    </div>
                    <div *ngIf="showRecetaGlobalGAP" class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center" (click)="generarGlobalGAP(data.data)"
                            tooltipPosition="top" pTooltip="Generar informe para Global GAP" style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #0baf4d;
                                color: white;
                                font-size: 12px;
                                font-weight: bold;
                                margin-left: 10px;
                                cursor: pointer;">
                            <span>G</span>
                        </button>
                    </div>
                </div>
            </td>
            <td *ngIf="showFitoValidado">
                <p-inputSwitch [(ngModel)]="data.is_validated" [disabled]="tareasApi.fitosanitariosPUT.isPerforming"
                    (onChange)="toggleRowValidation($event, data)">
                </p-inputSwitch>
            </td>
        </ng-template>
        <ng-template #footer let-col
            *ngIf="['donjardin', 'coliberica', 'demogardengest', 'fotoagricultura'].includes(this.appName)">
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['id'].includes(col.field))">
                TOTAL:
            </div>
            <div *ngIf="(['horas_totales'].includes(col.field))"
                style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
                {{this.totalHoras || 0}}
            </div>


        </ng-template>


    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <div class="mb-5 col-12">
            
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="5"
                    [fields]="formFieldsTiempo"
                    >
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsKM">
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsLugar">
            </app-form-builder>
            <div class="col-12">
                <app-form-builder [model]="model"  [distributionOrder]="distributionOrderFormTratamientos" [distributionColumns]="4" [fields]="formFieldsTratamiento">
                </app-form-builder>
            </div>
        </div>

        <div *ngIf="appName === 'vginer' || showClimatologia" class="mb-5 col-sm-6">
            <h5>Condiciones meteorológicas</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="4"
                    [fields]="formFieldsClima">
            </app-form-builder>
        </div>
        <div *ngIf="appName === 'vginer'" class="mb-5 col-sm-6">
            <h5>Mezclas sobrantes</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsMezclasSobrantes">
            </app-form-builder>
        </div>
        <div class="d-flex">
            <div class="mb-5 col-sm-6">
                <h5>Datos de la maquinaria</h5>
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        [fields]="formFieldsTractor">
                </app-form-builder>
            </div>
            <div class="mb-5 col-sm-6">
                <h5>Tarea finalizada</h5>
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        [fields]="formFieldsFinal"
                        >
                </app-form-builder>
            </div>
    
        </div>
        <div class="mb-5 col-12" *ngIf="this.appName === 'innovia'">
            <h5>Productos aplicados</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsInnovia">
            </app-form-builder>
        </div>
        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 15px; border: none"></th>
                    <th>Producto</th>
                    <th>Uso</th>
                    <th>Agente</th>
                    <th>C.&nbsp;Recomendada</th>
                    <th>C.&nbsp;Aplicada</th>
                    <th>F.&nbsp;Caducidad</th>
                    <th *ngIf="showProductosMaxAplicacion">Nº máx aplicaciones</th>
                    <th *ngIf="showProductosMaxAplicacion">Nº Aplicaciones</th>
                </tr>
                <!-- ocultar_en_receta -->
                <ng-container *ngFor="let item of model?.tratamiento?.linea; let i = index">
                    <tr [style.background]="item?.producto?.caducado"
                        [style.color]="item?.producto?.caducado === '#222222' ? 'white' : 'black'">
                        <td style="border: none; background: #f8f9fa !important; color: black !important;">{{i + 1}}
                        </td>
                        <td>
                            <span
                                *ngIf="![1, '1', true, 'true', null, 'null'].includes(item?.ocultar_en_receta || true)"
                                class="ml-1 badge badge-secondary">Oculto</span>
                            {{item?.producto?.nombre}}
                        </td>
                        <td>{{item?.uso_agente?.uso}}</td>
                        <td>{{item?.uso_agente?.agente}}</td>
                        <td>
                            <span *ngIf="item?.cantidad_cuaderno">
                                {{decimalFormat(item?.cantidad_cuaderno, 2, '.', ',', 3)}}
                                {{splitFormat(model.tratamiento?.uds_medida ?? '')[i]}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="item?.cantidad_real">
                                {{decimalFormat(item?.cantidad_real, 2, '.', ',', 3)}}
                                {{splitFormat(model.tratamiento?.uds_medida ?? '')[i]}}
                            </span>
                        </td>
                        <td>{{item?.producto?.vademecum?.fecha_caducidad || 'No figura'}}</td>
                        <td *ngIf="showProductosMaxAplicacion">{{item?.producto?.num_maximo_aplicaciones || 'No figura'}}</td>
                        <td *ngIf="showProductosMaxAplicacion">{{item?.producto?.veces_aplicado || '0'}}</td>
                    </tr>
                </ng-container>
            </table>
        </div>
        <br>
        <div
            *ngIf="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest')">
            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder [model]="model" [distributionColumns]="1" [fields]="formFieldsObs">
                    </app-form-builder>
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images [model]="model" folder="fitosanitarios" tipo="list"></app-upload-images>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder [model]="model" [distributionColumns]="1" [fields]="formFieldsOrdenTrabajo">
                    </app-form-builder>
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images [model]="model" folder="fitosanitarios" tipo="list"
                            nombre_imagen='image_after'></app-upload-images>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                    <p>Firma</p>
                    <img [src]="serverUrl + 'images/fitosanitarios/'+this.model.signature_img "
                        class="info-firma-img" alt="Firma">
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>
</p-dialog>

<p-dialog
    *ngIf="canShow['delete']" 
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <div class="mb-5 col-12">
            
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="5"
                    [fields]="formFieldsTiempo"
                    >
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsKM">
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsLugar">
            </app-form-builder>
            <div class="col-12">
                <app-form-builder [model]="model"  [distributionOrder]="distributionOrderFormTratamientos" [distributionColumns]="4" [fields]="formFieldsTratamiento">
                </app-form-builder>
            </div>
        </div>

        <div *ngIf="appName === 'vginer' || showClimatologia" class="mb-5 col-sm-6">
            <h5>Condiciones meteorológicas</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="4"
                    [fields]="formFieldsClima">
            </app-form-builder>
        </div>
        <div *ngIf="appName === 'vginer'" class="mb-5 col-sm-6">
            <h5>Mezclas sobrantes</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsMezclasSobrantes">
            </app-form-builder>
        </div>
        <div class="d-flex">
            <div class="mb-5 col-sm-6">
                <h5>Datos de la maquinaria</h5>
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        [fields]="formFieldsTractor">
                </app-form-builder>
            </div>
            <div class="mb-5 col-sm-6">
                <h5>Tarea finalizada</h5>
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        [fields]="formFieldsFinal"
                        >
                </app-form-builder>
            </div>
    
        </div>
        <div class="mb-5 col-12" *ngIf="this.appName === 'innovia'">
            <h5>Productos aplicados</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="formFieldsInnovia">
            </app-form-builder>
        </div>
        <div class="d-flex justify-content-center">
            <table class="table-style">
                <tr>
                    <th style="width: 15px; border: none"></th>
                    <th>Producto</th>
                    <th>Uso</th>
                    <th>Agente</th>
                    <th>C.&nbsp;Recomendada</th>
                    <th>C.&nbsp;Aplicada</th>
                    <th>F.&nbsp;Caducidad</th>
                    <th *ngIf="showProductosMaxAplicacion">Nº máx aplicaciones</th>
                    <th *ngIf="showProductosMaxAplicacion">Nº Aplicaciones</th>
                </tr>
                <!-- ocultar_en_receta -->
                <ng-container *ngFor="let item of model?.tratamiento?.linea; let i = index">
                    <tr [style.background]="item?.producto?.caducado"
                        [style.color]="item?.producto?.caducado === '#222222' ? 'white' : 'black'">
                        <td style="border: none; background: #f8f9fa !important; color: black !important;">{{i + 1}}
                        </td>
                        <td>
                            <span
                                *ngIf="![1, '1', true, 'true', null, 'null'].includes(item?.ocultar_en_receta || true)"
                                class="ml-1 badge badge-secondary">Oculto</span>
                            {{item?.producto?.nombre}}
                        </td>
                        <td>{{item?.uso_agente?.uso}}</td>
                        <td>{{item?.uso_agente?.agente}}</td>
                        <td>
                            <span *ngIf="item?.cantidad_cuaderno">
                                {{decimalFormat(item?.cantidad_cuaderno, 2, '.', ',', 3)}}
                                {{splitFormat(model.tratamiento?.uds_medida ?? '')[i]}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="item?.cantidad_real">
                                {{decimalFormat(item?.cantidad_real, 2, '.', ',', 3)}}
                                {{splitFormat(model.tratamiento?.uds_medida ?? '')[i]}}
                            </span>
                        </td>
                        <td>{{item?.producto?.vademecum?.fecha_caducidad || 'No figura'}}</td>
                        <td *ngIf="showProductosMaxAplicacion">{{item?.producto?.num_maximo_aplicaciones || 'No figura'}}</td>
                        <td *ngIf="showProductosMaxAplicacion">{{item?.producto?.veces_aplicado || '0'}}</td>
                    </tr>
                </ng-container>
            </table>
        </div>
        <br>
        <div
            *ngIf="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest')">
            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder [model]="model" [distributionColumns]="1" [fields]="formFieldsObs">
                    </app-form-builder>
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images [model]="model" folder="fitosanitarios" tipo="list"></app-upload-images>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <app-form-builder [model]="model" [distributionColumns]="1" [fields]="formFieldsOrdenTrabajo">
                    </app-form-builder>
                </div>
                <div class="col-lg-6">
                    <div style="display: flex;justify-content: space-between;">
                        <app-upload-images [model]="model" folder="fitosanitarios" tipo="list"
                            nombre_imagen='image_after'></app-upload-images>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                    <p>Firma</p>
                    <img [src]="serverUrl + 'images/fitosanitarios/'+this.model.signature_img "
                        class="info-firma-img" alt="Firma">
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
            style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>

<!-- VERSIÓN "Prescipción" -->
<p-dialog *ngIf="showPrescripcionTratamiento" header="Prescripción de Tratamiento" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv">
            <font color="white" size="2">{{str}}</font>

            <table width="100%" style="font-size: 12; border: 1px solid; border-collapse: collapse;">
                <tr style="border: 1px solid; border-collapse: collapse;">
                    <td style="border-right: 1px solid;">CLIENTE: {{receta.cliente_nombre}} - ({{receta.finca}} -
                        {{receta.sector}})</td>
                    <td>ALBARAN N.: {{currentYear}}/{{receta.id}}</td>
                </tr>
            </table>
            <br />

            <table width="100%" style="font-size: 12; border: 1px solid; border-collapse: collapse;">
                <tr>
                    <td style="border-right: 1px solid;">PARTIDA:{{receta.partida}}</td>
                    <td style="border-right: 1px solid;">BARRACA:</td>
                    <td style="border-right: 1px solid;">CHALET:</td>
                    <td style="text-align: right;">Fecha: {{receta.fecha_inicio}}</td>
                </tr>
            </table>
            <br />

            <table width="100%"
                style="text-align: center; font-size: 12; border: 1px solid; border-collapse: collapse;">
                <tr>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">CUBA</td>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">PLAZO SEGURIDAD</td>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">LITROS</td>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">PRECIO</td>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">MANO OBRA</td>
                    <td style=" border-bottom: 1px solid;">DESPLAZAMIENTO</td>
                </tr>

                <tr>
                    <td style="border-right: 1px solid;">{{receta.maquinaria}} ({{receta.capacidad_maquina}} L.)</td>
                    <td style="border-right: 1px solid;">{{ps}}</td>
                    <td style="border-right: 1px solid;">{{receta.litros}}</td>
                    <td style="border-right: 1px solid;"></td>
                    <td style="border-right: 1px solid;">{{(receta.mano_obra || 0) * (receta.litros || 0)}} €</td>
                    <td>{{receta.desplazamiento}}</td>
                </tr>
            </table>
            <br />

            <table width="100%"
                style="text-align: center; font-size: 12; border: 1px solid; border-collapse: collapse;">
                <tr>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">ORDEN</td>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">PRODUCTO</td>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">ENVASE</td>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">
                        {{appName === 'ortizlavado' ? 'DOSIS/HA' : 'DOSIS %'}}
                    </td>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">
                        {{appName === 'ortizlavado' ? 'DOSIS/1000 L' : 'DOSIS CUBA'}}
                    </td>
                    <td style="background: #dddddd; border-right: 1px solid; border-bottom: 1px solid;">NOTA</td>
                    <td style="border-right: 1px solid; border-bottom: 1px solid;">TOTAL L./K.</td>
                    <td style="border-bottom: 1px solid;">PRECIO</td>
                </tr>

                <tr *ngFor="let row of arrayProdsNom; let i = index">
                    <td style="background: #dddddd; border-right: 1px solid;">{{i+1}}</td>
                    <td style="background: #dddddd; border-right: 1px solid;">{{row}}</td>
                    <td style="background: #dddddd; border-right: 1px solid;"></td>
                    <td style="background: #dddddd; border-right: 1px solid;">{{arrayCantsReceta[i] | number}} </td>
                    <td style="background: #dddddd; border-right: 1px solid;">
                        {{((arrayCantsReceta[i] || 0) * (receta.capacidad_maquina || 0)) | number}} {{arrayUdsMedida[i]}}
                    </td>
                    <td style="background: #dddddd; border-right: 1px solid;">&nbsp;</td>
                    <td style="border-right: 1px solid;">{{((arrayCantsReceta[i] || 0) * (receta.litros || 0)) | number}}
                        {{arrayUdsMedida[i]}}</td>
                    <td></td>
                </tr>
            </table>
            <br />

            <table width="100%" style="font-size: 12; border: 1px solid; border-collapse: collapse;">
                <tr>
                    <td style="background: #dddddd; border-right: 1px solid;">
                        TELEFONO:<br />{{receta.cliente_telefono}}</td>
                    <td style="background: #dddddd; border-right: 1px solid;">LLAVE:<br />&nbsp;</td>
                    <td style="background: #dddddd; border-right: 1px solid;">
                        OBSERVACIONES:<br />{{receta.tratamiento_nombre}}
                    </td>
                    <td style="background: #dddddd; border-right: 1px solid;">POLIGONO:<br />&nbsp;</td>
                    <td style="background: #dddddd;">PARCELA:<br />&nbsp;</td>
                </tr>
            </table>
            <br />

            <table class="w-100" style="border: 1px solid; border-collapse: collapse;">
                <tr>
                    <td style="border-right: 1px solid;">
                        {{receta.fecha_completa}}
                    </td>
                    <td>
                        {{receta.cliente_nombre}}
                        <span *ngIf="receta?.cliente_nif">
                            (NIF: {{receta.cliente_nif}})
                        </span>
                    </td>
                </tr>
            </table>


            <!--
                    <form-builder
                            [fields]="formFields"
                            [distribution-columns]="4"
                            [distribution-order]="distributionOrder"
                            [model]="model">
                    </form-builder>
            -->
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono de MhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
    <br>

</p-dialog>


<!-- VERSIÓN 'BASIC' -->
<p-dialog *ngIf="showRecetaTratamiento" header="Receta de Tratamiento" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <!-- Receta de Melià -->
        <div *ngIf="currentUser?.nombre === 'ALEJANDRO'" id="html2canvasDiv" class="googoose registroDiv">
            <div class="w-100 py-2 text-center">
                <img src="./assets/logo_melia.png" style="width: 50%; height: auto" alt="" />
            </div>

            <!-- Tabla de arriba -->
            <div id="melia-top-table">
                <div class="w-100 d-flex">
                    <div style="border-right: none; border-bottom: none;">
                        <b>Propietario:</b>
                        {{receta.cliente_nombre}}
                    </div>
                    <div style="border-bottom: none;">
                        <b>Finca:</b>
                        {{receta.finca}}
                    </div>
                </div>
                <div class="w-100 d-flex">
                    <div style="border-right: none; border-bottom: none;">
                        <b>Fecha:</b>
                        {{receta.fecha_completa}}
                    </div>
                    <div style="flex: 2; border-bottom: none;">
                        <b>Objetivo del tratamiento:</b>
                        {{receta.tratamiento_nombre}}
                    </div>
                </div>
                <div class="w-100">
                    <div style="border-bottom: none;">
                        <b>Variedad (Sector):</b>
                        {{receta.sector}}
                    </div>
                </div>
                <div class="w-100 d-flex">
                    <div style="border-right: none;">
                        <b>Maquinaria:</b>
                        {{receta.maquinaria}}
                    </div>
                    <div>
                        <b>Aplicador(es):</b>
                        <br>
                        {{receta.trabajadores_nombres}}
                    </div>
                </div>
            </div>

            <!-- Tabla de abajo -->
            <table class="w-100 table-bordered table-hernandorena">
                <thead class="text-center" style="font-size: 0.7rem;">
                    <th class="p-1">Producto comercial</th>
                    <th class="p-1">Materia activa</th>
                    <th class="p-1">Nº&nbsp;Registro</th>
                    <th class="p-1">P.S.</th>
                    <th class="p-1">Dosis&nbsp;x 1000&nbsp;L.</th>
                </thead>
                <tbody *ngIf="receta?.tratamiento">
                    <tr *ngFor="let linea of receta.tratamiento?.linea; let i = index">
                        <td class="p-3">{{linea?.producto?.nombre}}</td>
                        <td class="p-3">{{linea?.producto?.materia_activa}}</td>
                        <td class="p-3">{{linea?.producto?.num_registro}}</td>
                        <td class="p-3">{{linea?.producto?.ps}}</td>
                        <td class="p-3">{{linea?.cantidad_real}}&nbsp;{{linea?.producto?.unidades}}</td>
                    </tr>
                </tbody>
            </table>

            <br>

            <div class="my-5">
                <h4 style="font-weight: bold; border-bottom: 2px double;">Notas:</h4>

                <ul>
                    <li>
                        Obligatorio el uso de EPI´s para la realización de los tratamientos.
                    </li>
                    <li>
                        No tratar con condiciones climáticas adversas (lluvia, viento, temperaturas extremas).
                    </li>
                    <li>
                        Seguir el orden de mezclas establecido.
                    </li>
                    <li>
                        Los sobrantes de caldo y/o los lavados de tanque debe realizarse en zona autorizada.
                    </li>
                    <li>
                        Para cualquier aclaración dirigirse al técnico responsable
                        <span *ngIf="currentUser.telefono">({{currentUser.telefono}})</span>
                    </li>
                </ul>
            </div>

            <br>

            <div class="text-center font-weight-bold">
                <h4>Técnico responsable: Alejandro Meliá Ballester</h4>
                <p>Asesor ROPO Nº 101200315SA</p>
            </div>
        </div>

        <!-- Receta genérica -->
        <div *ngIf="currentUser?.nombre !== 'ALEJANDRO'" id="html2canvasDiv" class="googoose">

            <font color="white" size="2">{{str}}</font>

            <font face="Courier New" size="4">
                <table width="100%" style="font-size: 14; font-family: Courier New;">
                    <tr>
                        <td *ngIf="appName === 'melia'">
                            <img src="./assets/logo_fitogest.png" style="width: 200px" alt="Logo FitoGEST">
                        </td>
                        <td *ngIf="appName === 'prosanzcu'">
                            <img src="./assets/logo_prosanzcu.png" style="width: 200px"  alt="Logo Prosanzcu">
                        </td>
                        <td *ngIf="appName === 'saviatropical'">
                            <img src="./assets/logo_saviatropical.png" style="width: 200px"  alt="Logo SaviaTropical">
                        </td>
                        <td>{{empresa}}</td>
                        <td>Ficha: {{receta.id}}</td>
                    </tr>
                </table>
                <br /><br /><br />

                <table width="100%" style="font-size: 14; font-family: Courier New;">
                    <tr>
                        <td width="80px"></td>
                        <td>PROPIETARIO: {{receta.cliente_nombre}}</td>
                        <td>FECHA: {{receta.fecha_inicio}}</td>
                    </tr>
                    <tr>
                        <td width="80px"></td>
                        <td>FINCA: "{{receta.finca}}"</td>
                        <td>SECTOR: "{{receta.sector}}"</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="receta?.parcelas">
                        <td width="80px"></td>
                        <td>
                            <ul class="parcelas-list">
                                PARCELAS:
                                <li *ngFor="let parcela of receta?.parcelas; index as i">
                                    {{parcela?.nombre}}
                                    <span *ngIf="i < (receta?.parcelas || []).length - 1">, </span>
                                </li>
                            </ul>
                        </td>
                        <td></td>
                    </tr>
                </table>
                <br />

                <table width="100%" style="font-size: 14; font-family: Courier New;">
                    <tr>
                        <td>TRATAMIENTO: {{receta.tratamiento_nombre}}</td>
                    </tr>
                </table>
                <br /><br />

                <table width="100%" style="text-align: center; font-size: 14; font-family: Courier New;">
                    <tr>
                        <td>DOSIS</td>
                        <td>PRODUCTO</td>
                        <td>CANTIDAD</td>
                        <td>ORDEN</td>
                    </tr>
                    <tr>
                        <td>=====</td>
                        <td>========</td>
                        <td>========</td>
                        <td>=====</td>
                    </tr>


                    <tr *ngFor="let row of arrayProdsNom; let i = index">
                        <td>{{arrayCantsReceta[i]}} {{arrayUdsMedida[i]}} </td>
                        <td>{{row}}</td>
                        <td>{{receta.litros}} LITROS</td>
                        <td>{{i+1}}</td>
                    </tr>
                </table>
                <br /><br />

                <table width="100%" style="font-size: 14; font-size: 14; font-family: Courier New;">
                    <tr>
                        <td>NOTA: {{receta.observaciones}}</td>
                    </tr>
                </table>
            </font>
        </div>

        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono Whatsapp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
</p-dialog>

<!-- VERSIÓN "Simat" -->
<p-dialog *ngIf="showFichaTratamiento" header="Ficha de Tratamiento" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">

        <div id="html2canvasDiv" class="recetaDiv">
            <div>
                <img src="../../../../../assets/logo-simat-agricola.png" style="width: 100px; height: auto;" alt="Logo Simat"/>
                <h5 class="text-center">HOJA DE TRATAMIENTOS FITOSANITARIOS</h5>
                <table class="receta-table">
                    <tr>
                        <td colspan="2">FECHA EMISIÓN ORDEN:</td>
                        <td class="centered">
                            {{receta.fecha_inicio}}
                        </td>
                        <td>PRODUCTOR:</td>
                        <td class="centered" style="background-color: #b1a0c7">
                            {{receta.cliente_nombre}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bold">FINCA/-S:</td>
                        <td class="bold">PARCELA</td>
                        <td class="bold centered">VARIEDAD/-ES</td>
                        <td class="bold">SUPERFICIE Ha</td>
                        <td class="bold centered">CÓDIGO PARCELA:</td>
                    </tr>
                    <tr class="more-padding">
                        <td>{{receta.finca}}</td>
                        <td style="background-color: #dce6f1;">
                            <ul class="parcelas-list">
                                <li *ngFor="let parcela of receta?.parcelas; index as i">
                                    {{parcela?.nombre}}
                                    <span *ngIf="i < (receta?.parcelas || []).length-1">, </span>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="parcelas-list">
                                <li *ngFor="let parcela of receta?.parcelas; index as i">
                                    {{parcela?.variedad}}
                                    <span *ngIf="i < (receta?.parcelas || []).length-1">, </span>
                                </li>
                            </ul>
                        </td>
                        <td>{{receta.superficie_parcelas}}</td>
                        <td>
                            <input type="text" class="print-input" />
                        </td>
                    </tr>
                </table>
                <table border="1" style="font-size: 12px;">
                    <tr>
                        <td class="bold">Tipo de plaga o afección:</td>
                    </tr>
                    <tr>
                        <td>{{receta.tipo_plaga || '-'}}</td>
                    </tr>
                    <tr>
                        <td class="bold">Motivo del tratamiento</td>
                    </tr>
                    <tr>
                        <td>{{receta.motivo || '-'}}</td>
                    </tr>
                </table>

                <br>

                <table class="receta-table">
                    <thead class="more-padding">
                        <th>Producto</th>
                        <th>Materia activa</th>
                        <th>Número de registro</th>
                        <th>Dosis / 1000 litros</th>
                        <th>Hora de aplicación</th>
                        <th>Fecha de aplicación</th>
                        <th style="width: 10%">Horas</th>
                        <th style="width: 10%">Litros</th>
                        <th>Plazo de seguridad (días)</th>
                    </thead>
                    <tr *ngFor="let tratamiento of receta.tratamiento?.linea ; let i = index" class="more-padding">
                        <td>{{tratamiento?.producto?.nombre}}</td>
                        <td>{{tratamiento?.producto?.materia_activa}}</td>
                        <td>{{tratamiento?.producto?.num_registro}}</td>
                        <td>{{arrayCantsReceta[i] | number}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{tratamiento?.producto?.ps}}</td>
                    </tr>
                    <tr>
                        <td colspan="6" style="background-color: #f8f9fa; border: none;"></td>
                        <td style="vertical-align: top; height: 3rem;">Total horas:</td>
                        <td style="vertical-align: top; height: 3rem;">Total litros:</td>
                        <td></td>
                    </tr>
                </table>

                <br>

                <table class="receta-table">
                    <tr>
                        <td>Observaciones:</td>
                        <td style="width: 400px; text-align: center">Fda. Autorización por: JAVIER BOLUFER BOU (ROPO:
                            <b>104601205SA</b>)
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 4.5rem;">
                            <textarea class="print-input"
                                style="width: 100%; height: 100%; resize: none; overflow: hidden">{{receta.observaciones}}</textarea>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="border: none">
                            <small>
                                Utilizar EPI adecuado. Leer detenidamente la etiqueta antes de realizar la primera
                                mezcla.
                            </small>
                        </td>
                    </tr>
                    <tr>
                        <td class="more-padding">
                            ¿Se respeta el plazo de re-entrada (ver en la etiqueta del producto) en todos los
                            casos?&nbsp;
                            <input style="width: auto !important" type="radio" name="plazo_reentrada" /> SÍ &nbsp;
                            <input style="width: auto !important" type="radio" name="plazo_reentrada" /> NO &nbsp;
                            <b>Verificado por (firma)</b>
                        </td>
                    </tr>
                </table>

                <br>

                <table class="receta-table">
                    <tr>
                        <td class="bold">Máquina</td>
                        <td class="bold">Marcha</td>
                        <td class="bold">RPM</td>
                        <td class="bold">Presión (atmósferas-bar)</td>
                        <td class="bold">Boquillas</td>
                    </tr>
                    <tr>
                        <td>{{receta.maquinaria}}</td>
                        <td>{{receta.marcha}}</td>
                        <td>{{receta.rpm}}</td>
                        <td>{{receta.presion}}</td>
                        <td>{{receta.boquillas}}</td>
                    </tr>
                </table>

                <br>

                <table class="receta-table">
                    <tr>
                        <td style="width: 25%; text-align: center">Nombre y apellidos del/los operario/-s</td>
                        <td style="width: 15%; text-align: center">ROPO</td>
                        <td>Firmas:</td>
                    </tr>
                    <tr style="height: 100px;">
                        <td>
                            <ul class="operarios-list">
                                <li><span>1</span></li>
                                <li><span>2</span></li>
                                <li><span>3</span></li>
                                <li><span>4</span></li>
                            </ul>
                        </td>
                        <td>
                            <ul class="operarios-list">
                                <li><span>1</span></li>
                                <li><span>2</span></li>
                                <li><span>3</span></li>
                                <li><span>4</span></li>
                            </ul>
                        </td>
                        <td style="border: none; display: flex; font-size: smaller">
                            <div style="width: 25%">1</div>
                            <div style="width: 25%">2</div>
                            <div style="width: 25%">3</div>
                            <div style="width: 25%">4</div>
                        </td>
                    </tr>
                </table>

                <br>

                <table class="receta-table">
                    <tr>
                        <td>Observaciones:<br>
                            <textarea class="print-input"
                                style="width: 100%; height: inherit; resize: none; overflow: hidden">El caldo sobrante o de limpieza se aplica en _________________________________________________________________________________________ y litros ____________________________________.</textarea>
                        </td>
                    </tr>
                </table>

                <br>

                <div style="font-size: 16px; width: 60%; display: flex; justify-content: space-around;">
                    <span>Lluvia <input style="width: auto !important" type="checkbox" /></span>
                    <span>Calma <input style="width: auto !important" type="checkbox" /></span>
                    <span>Viento flojo <input style="width: auto !important" type="checkbox" /></span>
                    <span>Viento medio <input style="width: auto !important" type="checkbox" /></span>
                    <span>Viento fuerte <input style="width: auto !important" type="checkbox" /></span>
                </div>

            </div>
        </div>

        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>

        <br>

        <h6 class="text-center small">
            Por favor, antes de hacer click en <b>Imprimir</b>, asegúrese de maximizar
            esta ventana para que la imagen resultante quede del tamaño correcto.
        </h6>
    </div>
    <br>

</p-dialog>

<!-- VERSIÓN "La Fast" -->
<p-dialog *ngIf="showRegistroTratamiento" header="REGISTRO DE ACTUACIONES" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta && appName !== 'pyf'" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">
            <!-- Formato para La FAST -->
            <div *ngIf="appName === 'lafast'">
                <p
                    style="font-size: x-large; position: absolute; right: 8.5rem; top: 2rem; font-family: 'Times New Roman', Times, serif">
                    Nº {{receta.id}}
                </p>
                <p style="font-size: x-large; position: absolute; right: 4.8rem; top: 2rem;"><b>H</b></p>
            </div>
            <div class="flex-row">
                <!-- Formato para La FAST -->
                <img *ngIf="appName === 'lafast'" src="assets/logo_coplaca_natur.png"
                    style="width: 100px; height: auto;" alt="Logo Coplaca" />
                <p *ngIf="appName === 'lafast'" class="large">REGISTRO DE ACTUACIONES</p>

                <!-- Otros logos -->
                <div style="max-width: 30%; padding: 1rem">
                    <img *ngIf="appName !== 'lafast'" [src]="logoUrl" style="width: 100%; height: auto" alt="Logo LaFAST"/>
                </div>
                <div>
                    <p class="large">Titular: {{receta.cliente_nombre}}</p>
                    <p class="large">Finca: {{receta.finca}}</p>
                </div>
                <div *ngIf="this.appName !== 'torrepacheco'" style="text-align: right">
                    <p class="large">(Año/nº registro: {{receta.anyo}}/{{receta.id}})</p>
                    <p class="large">Código trazabilidad: _______________</p>
                </div>
            </div>
            <div>
                <p>1.- CONTROL DE PLAGAS, ENFERMEDADES, MALAS HIERBAS Y FISIOPATÍAS</p>
                <p style="margin-left: 1rem;">Tratamientos Fitosanitarios</p>
                <table class="registro-table" border="1">
                    <thead>
                        <th style="width: 8%">Nº&nbsp;registro</th>
                        <th style="width: 18%">Producto</th>
                        <th style="width: 12%">Materia activa</th>
                        <th style="width: 8%">P.S.</th>
                        <th style="width: 12%">Motivo</th>
                        <th style="width: 8%">Dosis</th>
                        <th style="width: 10%" *ngIf="!(appName === 'armentia')">pH caldo</th>
                        <th style="width: 12%">Volumen caldo</th>
                        <th style="width: 12%">Eficacia</th>
                    </thead>
                    <tr *ngFor="let linea of receta.tratamiento?.linea; let i = index">
                        <td>{{linea?.producto?.num_registro}}</td>
                        <td>{{linea?.producto?.nombre}}</td>
                        <td>{{linea?.producto?.materia_activa}}</td>
                        <td>{{linea?.producto?.ps}}</td>
                        <td *ngIf="linea?.uso_agente?.agente">
                            {{linea?.uso_agente?.agente}}
                        </td>
                        <td *ngIf="!linea?.uso_agente?.agente && receta?.motivo">
                            {{receta.motivo}}
                        </td>
                        <td *ngIf="!linea?.uso_agente?.agente && !receta?.motivo">
                            {{receta.tratamiento_nombre}}
                        </td>
                        <td>
                            <span *ngIf="(appName !== 'armentia') && arrayCantsReceta[i] && receta.tratamiento && receta.tratamiento.linea">
                                {{decimalFormat(arrayCantsReceta[i], 2, '.', ',', 3) + ' ' +
                                receta.tratamiento.linea[i]?.producto?.unidades || ''}}
                            </span>
                            <span *ngIf="(appName === 'armentia') && linea?.cantidad_cuaderno">
                                {{decimalFormat(linea?.cantidad_cuaderno, 2, '.', ',', 3) + ' ' +
                                receta.tratamiento?.uds_medida || ''}}
                            </span>
                        </td>
                        <td *ngIf="!(appName === 'armentia')">{{receta.ph_caldo}}</td>
                        <td>{{receta.litros}}</td>
                        <td>{{receta.eficacia}}</td>
                    </tr>
                </table>

                <br>

                <div>
                    <p style="margin-left: 1rem;">Parcelación tratamientos</p>

                    <table class="registro-table" border="1">
                        <thead>
                            <th style="width: 13%">{{sectorName}}</th>
                            <th style="width: 13%">{{parcelaName + '/s'}}</th>
                            <th style="width: 16%" class="dark-bg">Fecha fin del tratamiento</th>
                            <th style="width: 16%" class="dark-bg">Fin plazo seguridad</th>
                            <th style="width: 14%">Firma ejecución</th>
                            <th style="width: 14%">Código aplicador/es</th>
                            <th style="width: 14%">Código maquinaria</th>
                        </thead>
                        <tr>
                            <td>{{receta.sector}}</td>
                            <td>
                                <ul class="parcelas-list">
                                    <li *ngFor="let parcela of (receta?.parcelas || []); index as i">
                                        {{parcela?.nombre + ' ('+ parcela?.patron +')'}}
                                        <span *ngIf="i < (receta?.parcelas || []).length - 1">, </span>
                                    </li>
                                </ul>
                            </td>
                            <td class="gray-bg">{{receta.fecha_fin}}</td>
                            <td class="gray-bg">{{getFinPlazoSeguridad()}}</td>
                            <td style="height: 3rem"></td>
                            <td *ngIf="!(appName === 'armentia')">{{receta.ids_trabajadores}}</td>
                            <td *ngIf="(appName === 'armentia')">{{receta.trabajadores_nombres}}</td>

                            <td *ngIf="!(appName === 'armentia')">{{receta.id_maquinaria}}</td>
                            <td *ngIf="(appName === 'armentia')">{{receta.maquinaria}}</td>
                        </tr>
                    </table>

                    <br>

                    <div *ngIf="showTareasBio && !showRegistroTratamientoShort">
                        <p style="margin-left: 1rem;">Otras aplicaciones (no fitosanitarios)</p>

                        <table border="1" class="registro-table">
                            <thead>
                                <th style="width: 13%">{{sectorName}}</th>
                                <th style="width: 13%">{{parcelaName + '/s'}}</th>
                                <th style="width: 16%">Fecha aplicación</th>
                                <th style="width: 16%">Producto</th>
                                <th style="width: 14%">Modo de acción</th>
                                <th style="width: 14%">Forma aplicación</th>
                                <th style="width: 14%">Dosis</th>
                            </thead>
                            <tr *ngFor="let bio of receta?.bios">
                                <td>{{bio.sector}}</td>
                                <td>{{bio.parcela}}</td>
                                <td>{{bio.fecha_aplicacion}}</td>
                                <td>{{bio.producto}}</td>
                                <td>{{bio.modo_accion}}</td>
                                <td>{{bio.forma_aplicacion}}</td>
                                <td>{{bio.dosis}}</td>
                            </tr>
                            <tr *ngIf="!receta?.bios">
                                <td style="height: 3rem"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>

                        <br>
                    </div>

                    <div *ngIf="showTareasFertilizantes && !showRegistroTratamientoShort">
                        <div *ngIf="appName !== 'covidai' ">
                            <p>2.- FERTILIZACIÓN</p>
                            <table border="1" class="registro-table">
                                <thead>
                                    <th style="width: 45%" class="smaller">Cantidades, distribución y modo aplicación
                                    </th>
                                    <th style="width: 25%" class="smaller">Firma ejecución:</th>
                                    <th style="width: 25%" class="smaller">Nº de plantas:</th>
                                    <th style="width: 5%" class="smaller">Max</th>
                                </thead>
                                <tr>
                                    <td rowspan="6" class="smaller">
                                        <span style="position: relative; top: 3.4rem;">
                                            Próximo cambio:
                                        </span>
                                    </td>
                                    <td rowspan="6"></td>
                                    <td class="smaller">Registro de aportes período:</td>
                                    <td class="smaller">Año</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="smaller">(g/pl)</td>
                                </tr>
                                <tr>
                                    <td class="smaller">N:</td>
                                    <td class="smaller"></td>
                                </tr>
                                <tr>
                                    <td class="smaller">P<sub>2</sub>O<sub>5</sub>:</td>
                                    <td class="smaller"></td>
                                </tr>
                                <tr>
                                    <td class="smaller">K<sub>2</sub>0:</td>
                                    <td class="smaller"></td>
                                </tr>
                                <tr>
                                    <td class="smaller">CaO:</td>
                                    <td class="smaller"></td>
                                </tr>
                            </table>
                        </div>

                        <br>
                    </div>
                </div>

                <table border="1" class="registro-table">
                    <thead>
                        <th style="width: 45%" class="smaller">Firma del encargado:</th>
                        <th style="width: 25%" class="smaller">Firma y nº ROPO del técnico asesor:</th>
                        <th style="width: 30%; visibility: hidden" *ngIf="appName !=='ctl'"></th>
                        <th style="width: 30%;" *ngIf="appName === 'ctl'">Notas</th>
                    </thead>
                    <tr>
                        <td style="vertical-align: top" class="smaller">
                            Recibo esta instrucción y me comprometo a su ejecución, informando puntualmente al técnico
                            asesor en caso de no poder llevarla a cabo según lo indicado.
                        </td>
                        <td style="height: 5rem" class="smaller">
                            <span style="position: relative; top: 1.8rem;">
                                Nº: {{receta.usuario_ropo}}
                            </span>
                        </td>
                        <td style="visibility: hidden" *ngIf="appName !== 'ctl'"></td>
                        <td style="width: 30%; vertical-align: text-top;" *ngIf="appName === 'ctl'">{{
                            receta.observaciones }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
            <br>
        </div>

        <br>

        <h6 class="text-center small">
            Por favor, antes de hacer click en <b>Imprimir</b>, asegúrese de maximizar
            esta ventana para que la imagen resultante quede del tamaño correcto.
        </h6>
    </div>

    <div *ngIf="showReceta && appName === 'pyf'" style="margin-left: 20px; margin-right: 20px; width: 80vw !important">
        <div id="html2canvasDiv" class="registroDiv" style="font-size: 10px;min-height: 820px; overflow: auto;">
            <div class="flex-row">

                <!-- Otros logos -->
                <table style="min-width: 90%; max-width: 90%;margin:auto">
                    <thead>
                        <tr>
                            <th style="border: 1px solid black; text-align: center; ">
                                <img src="assets/pyf/asegapyf.png" style=" height: auto" alt="AsegaPYF"/>
                            </th>
                            <th style="border: 1px solid black;  text-align: center;">
                                <h4>ORDEN DE TRATAMIENTO</h4>
                            </th>
                            <th style="border: 1px solid black; text-align: center;">
                                <p *ngIf="appName !== 'pyf'">Nº registro: {{receta.id}} </p>
                                <p *ngIf="appName !== 'pyf'">Año: {{receta.anyo}} </p>
                                <p *ngIf="appName === 'pyf'">Orden: {{receta.referencia_receta}}</p>
                                <p *ngIf="appName === 'pyf'">Fecha Receta: {{receta.fecha_inicio}}</p>
                            </th>
                        </tr>
                    </thead>
                </table>

            </div>
            <div style="max-width: 90%;min-width: 90%; margin: 0 auto;">
                <div>
                    <p>Titular: {{receta.cliente_nombre}}</p>
                    <p>{{fincaName}}: {{receta.finca}}</p>
                    <p style="overflow-wrap: break-word; max-width:100%">{{parcelasName}}: {{receta.parcelas_nombres}}</p>
                    <p>Recomendación: {{receta.observaciones}}</p>
                </div>
                <div style="text-align: right">

                </div>

            </div>
            <div>
                <br>
                <table style="min-width: 90%;max-width: 90%; margin:auto">
                    <thead>
                        <tr>
                            <th style="border: 1px solid black; ">PRODUCTO COMERCIAL</th>
                            <th style="border: 1px solid black; ">Nº DE REGISTRO</th>
                            <th style="border: 1px solid black; ">MATERIA ACTIVA</th>
                            <th style="border: 1px solid black; ">P.S</th>
                            <th style="border: 1px solid black; ">DOSIS</th>
                            <th style="border: 1px solid black; ">MOTIVO DEL TRATAMIENTO</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="receta.tratamiento">
                        <tr *ngFor="let linea of receta.tratamiento.linea">
                        <tr *ngFor="let linea of (receta?.tratamiento?.linea || []); let i = index">
                            <td style="border: 1px solid black; padding: 5px;">{{linea?.producto?.nombre}}</td>
                            <td style="border: 1px solid black; padding: 5px;">{{linea?.producto?.num_registro}}</td>
                            <td style="border: 1px solid black; padding: 5px;">{{linea?.producto?.materia_activa}}</td>
                            <td style="border: 1px solid black; padding: 5px;">{{linea?.producto?.ps}}</td>

                            <td style="border: 1px solid black; padding: 5px;">
                                <span *ngIf="linea?.cantidad_cuaderno">
                                    {{decimalFormat(linea?.cantidad_cuaderno, 2, '.', ',', 3) + ' ' +
                                    parts[i] || ''}}
                                </span>
                            </td>
                            <td style="border: 1px solid black; padding: 5px;">
                                <div *ngIf="appName !== 'pyf'">
                                    <div *ngIf="receta?.motivo_justificacion">
                                        {{receta.motivo_justificacion}}
                                    </div>
                                </div>
                                <div *ngIf="appName === 'pyf'">
                                    <div *ngIf="linea?.uso_agente?.agente">
                                        {{linea?.uso_agente?.agente}}
                                    </div>
                                </div>

                            </td>



                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="margin: 20px;
            margin-left: 65px !important;">
                <img src="assets/pyf/nota.png" style="width: 650px; height: 80px;" alt="">
            </div>
            <div style="min-width: 90%;max-width: 90%; margin:auto; margin-top: 20px;">
                <div class="ui-g">
                    <div class="ui-g-8">
                        <h6 style="    background: #306830;
                        color: #0fdd0f;">TRIPLE <span style="color: white;">LAVADO</span> </h6>
                        <ul style="list-style: none;">
                            <li style="list-style: none;">1.- Vacíe el contenido del envase en el tanque de la
                                pulverizadora y mantenga en posición de descarga por 30 segundos.</li>
                            <li style="list-style: none;">2.- Adicione agua limpia al envase, hasta 1/4 de su capacidad.
                            </li>

                            <li style="list-style: none;">
                                3.- Cierre el envase y agite durante 30 segundos.

                            </li>
                            <li style="list-style: none;">

                                4.- Vierta el enjuague en el tanque de la pulverizadora y mantenga el envase en posición
                                de descarga por 30 segundos.
                            </li>
                            <li style="list-style: none;">
                                5.- Perfore el envase para evitar su reutilización.

                            </li>
                            <li style="list-style: none;">
                                6.- Deposite el envase en un lugar autorizado.

                            </li>
                        </ul>


                    </div>
                    <div class="ui-g-4">
                        <div style="display: flex;">
                            <img src="assets/pyf/tripple.jpg" style="width: 100px;" alt="Tripple">
                            <img src="assets/pyf/lavado.png" style="width: 100px;" alt="Lavado">
                        </div>
                    </div>
                    <div>

                    </div>

                </div>
                <table style="min-width: 90%; max-width: 90%;margin:auto">
                    <tr>
                        <td>
                            <b>CONFORME:</b>
                        </td>
                        <td>
                            <b>El técnico:</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Firma del Aplicador:</b>
                        </td>
                        <td>
                            <b>Firma:</b>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
    <div style="min-height: 150px;"></div>
    <div class="d-flex justify-content-center" *ngIf="appName === 'pyf'">
        <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
        <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
            <i class="fa fa-print"></i>
            Imprimir
        </button>
        <button *ngIf="showEnviarWhatsapp"
            [disabled]="this.currentUser.rol === 'user'"
            class="btn btn-success mx-2" (click)="enviarWhatsapp();">
            <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
            Enviar WhatsApp
        </button>
        <button *ngIf="showEnviarCorreo"
            [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                    !(receta.finca_asociada?.email || receta.cliente_email) || (this.currentUser.rol === 'user')"
            (click)="enviarCorreo()" class="btn mx-2"
            [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

            <ng-container *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                <i class="fa fa-envelope"></i>
                <span *ngIf="isGeneratingCanvas">
                    Generando pdf...
                </span>
                <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                    Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                    receta.cliente_email}}
                </span>
                <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                    Enviar email
                </span>
            </ng-container>
            <ng-container *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                <i class="fa fa-envelope"></i>
                Enviando...
            </ng-container>
            <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                <i class="fa fa-exclamation-triangle"></i>
                {{tareasApi.enviarRecetaCaptura.error}}
            </ng-container>
        </button>
    </div>
    <br>

    <!--     <h6 class="text-center small">
        Por favor, antes de hacer click en <b>Imprimir</b>, asegúrese de maximizar
        esta ventana para que la imagen resultante quede del tamaño correcto.
    </h6>
    <br> -->

</p-dialog>

<!-- VERSIÓN "Hernandorena" -->
<p-dialog *ngIf="showRegistroHernandorena" header="Generar receta" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">

            <div class="d-flex mb-5" style="font-size: 0.7rem; height: 120px;">
                <div id="margen" style="flex: 1" class="d-flex flex-column justify-content-between">
                    <span style="font-weight: bold; text-decoration: underline; font-size: 0.85rem;">
                        TRATAMIENTO FITOSANITARIO
                        <img src="./assets/logo_hernandorena.png" style="width: 80px; height: auto; display: block;"
                            alt="" />
                    </span>
                    <span>CENTRO: {{receta.finca}}</span>
                    <span *ngIf="this.appName === 'hernandorena'">EPI: {{receta.epi}}</span>
                    <span>SUBPARCELA(S):
                        <span *ngFor="let parcela of (receta?.parcelas || []); let i = index">
                            {{parcela?.nombre}}<span *ngIf="i < (receta?.parcelas || []).length - 1">, </span>
                        </span>
                    </span>
                    <span>
                        {{receta.fecha_completa}}
                    </span>
                </div>
                <div class="d-flex justify-content-around" style="flex: 1">
                    <span style="font-weight: bold; text-decoration: underline; font-size: 0.85rem;">
                        {{(receta.motivo || '').toUpperCase()}}
                    </span>
                    <div class="d-flex" style="height: 2rem;">
                        <input type="text" size="1" maxlength="1" class="text-center mr-1" style="width: 3rem !important;
                                font-size: 1.2rem !important;
                                text-transform: uppercase" />
                        <div style="font-size: 0.7rem; width: 2.5rem;">
                            I, P, A
                        </div>
                    </div>
                </div>
                <div style="flex: 1">
                    <table class="w-100 table-bordered table-hernandorena" style="font-size: 0.7rem;">
                        <thead class="text-center">
                            <th>CENTRO</th>
                            <th>GASTO CALDO (L)</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of [1, 2, 3, 4, 5]">
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="border: 1px solid transparent !important;">
                                    <i>* Tachar en centro que no se haga</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <table class="w-100 table-bordered table-hernandorena">
                <thead class="text-center" style="font-size: 0.7rem;">
                    <th class="p-1">MARCA COMERCIAL</th>
                    <th class="p-1">MATERIA ACTIVA</th>
                    <th class="p-1">DOSIS (cc o g/100L)</th>
                    <th class="p-1">ORG. COMBATIR</th>
                </thead>
                <tbody *ngIf="receta?.tratamiento && receta.agentes">
                    <tr *ngFor="let linea of receta.tratamiento?.linea; let i = index">
                        <td class="p-3">{{linea?.producto?.nombre}}</td>
                        <td class="p-3">{{linea?.producto?.materia_activa}}</td>
                        <td class="p-3">{{linea?.cantidad_real}}</td>
                        <td class="p-3">{{receta.agentes[i]}}</td>
                    </tr>
                    <tr>
                        <td colspan="4" style="height: 100px">
                            <span style="font-size: 0.7rem;
                                    font-weight: bold;
                                    position: relative;
                                    top: calc(-50% + 0.35rem);">
                                Observaciones OPERARIO:
                            </span>
                            {{receta.observaciones}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between mt-2" style="font-size: 0.7rem;">
                <span style="flex: 3">Ordenante: firma y fecha</span>
                <span style="flex: 2">Realizador: firma y fecha</span>
            </div>

        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
        </div>
    </div>
    <br>

</p-dialog>


<!-- VERSIÓN "Pascual Cabedo" -->
<p-dialog *ngIf="showRecetaPascualCabedo" [header]="nameReceta" [(visible)]="showReceta" (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv mx-auto"
            style="font-family: 'Times New Roman', Times, serif; font-size: 12pt; width: 900px;">
            <div class="text-center">
                <img [src]="logoUrl" style="width: 220px; height: auto;"  alt="Logo"/>
            </div>

            <div class="mt-1 mb-4" style="border: 1px solid #8f8f8f; outline: 1px #c5c5c5;">
                <ul class="p-0 m-0 ml-1" style="list-style: none; color: #006600;">
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">FECHA:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta.fecha_inicio}}
                        </span>
                    </li>
                    <li *ngIf="appName !=='fotoagricultura'">
                        <span class="font-weight-bold" style="text-decoration: underline;">CLIENTE:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta.cliente_nombre}}
                        </span>
                    </li>
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">FINCA:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta.finca}}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="my-3" style="border: 1px solid #8f8f8f; outline: 1px #c5c5c5;">
                <ul class="p-0 m-0 ml-1" style="list-style: none; color: #006600;">
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">VARIEDAD(ES):</span>
                        <span *ngIf="receta?.parcelas"
                            style="color: #0c0c0c;font-family: Arial, Helvetica, sans-serif;">
                            <span *ngFor="let parcela of (receta?.parcelas || []); let i = index">
                                {{parcela?.variedad}}
                                <span *ngIf="i < ((receta?.parcelas || []).length - 1)">, </span>
                            </span>
                        </span>
                    </li>
                    <li>
                        <span class="font-weight-bold" style="text-decoration: underline;">MOMENTO DE APLICACIÓN:</span>
                        <span style="color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                            {{receta.momento_aplicacion}}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="my-1 p-1" style="color: #006600;">
                <p class="mb-2 font-weight-bold" style="font-size: 12pt;">
                    INDICACIONES:
                </p>
                <p class="ml-3 font-weight-bold" style="color: #0c0c0c; font-size: 12pt;">
                    Volumen Caldo/Hectárea: {{receta.litros}}&nbsp;L.
                </p>
                <p class="ml-3 font-weight-bold" style="color: #0c0c0c; font-size: 12pt;">
                    Máquinaria:
                </p>
                <ul>
                    <li>Nombre: {{receta.maquinaria}}</li>
                    <li>Marcha: {{receta.marcha}}</li>
                    <li>RPM: {{receta.rpm}}</li>
                    <li>Presión (atm-bar): {{receta.presion}}</li>
                    <li>Boquillas: {{receta.boquillas}}</li>
                </ul>
                <table class="table-pascual-cabedo my-4 mx-auto">
                    <tr class="font-weight-bold">
                        <td class="px-1 pt-1 pb-2" style="background:dimgrey; color:white;">DOSIS POR TANQUE</td>
                        <td class="px-1 pt-1 pb-2" style="background:dimgrey; color:white;"> {{appName === 'ava'?'1500L': appName === 'agrosalvi' ? '100 L' : '1000 L'}}</td>
                    </tr>
                    <tr class="font-weight-bold">
                        <td class="p-1" style="background:dimgrey; color:white;">PRODUCTO</td>
                        <td class="p-1" style="background:dimgrey; color:white;">DOSIS</td>
                    </tr>
                    <tr *ngFor="let linea of (receta?.tratamiento?.linea || [])">
                        <td class="p-1">
                            {{linea?.producto?.nombre}}
                        </td>
                        <td class="p-1">
                            <!-- 
                                En algunas ocasiones el intervalo recomendado de dosis está en %,
                                aunque la unidad del producto sean Kg, por lo que en vez de 3 Kg, se mostraria 0.3 Kg.

                                A Fotoagricultura eso le repercutia negativamente en otro lado de la aplicación (puede que Costes), así que pidió que se hiciese la rectificación. 
                                
                                En caso de otros supuestos, esta se encargará de poner los valores de forma que salgan bien.
                            -->
                            {{this.appName === 'fotoagricultura' ? decimalFormat(((linea?.cantidad_real || 0)/100)*1000, 2) :
                            decimalFormat(linea?.cantidad_real, 2)}}&nbsp;{{linea?.producto?.unidades}}
                        </td>
                    </tr>
                </table>

                <div class="my-3">
                    <p style="font-style: italic; text-decoration: underline; font-size: 12pt;">
                        NOTAS:
                    </p>
                    <div class="p-3"
                        style="font-size: 20px; color: #0c0c0c; font-family: Arial, Helvetica, sans-serif;">
                        {{receta.observaciones}}
                    </div>
                </div>
            </div>

            <div *ngIf="appName === 'pascualcabedo' || appName === 'fotoagricultura'">
                <p class="mb-2 font-weight-bold" style="
                    font-style: italic;
                    font-size: 12pt;
                    font-family: 'Times New Roman', Times, serif;
                    text-decoration: underline;
                    color: #9bbb59;">
                    OBSERVACIONES Y RECOMENDACIONES IMPORTANTES <b>(FOLIAR)</b>:
                </p>
                <div class="mb-4" style="font-family: Arial, Helvetica, sans-serif; font-size: 10pt;">
                    <b>No pulverizar con temperaturas superiores a 30 grados o con vientos de poniente.</b>
                    <br />
                    <span style="color: #ff0000;">LEER SIEMPRE LAS ETIQUETAS ANTES DEL USO DE LOS PRODUCTOS
                        RECOMENDADOS.</span>
                    <br />
                    <b>N</b>o sobrepasar las dosis recomendadas sin consultar al servicio técnico de esta empresa.
                    <br />
                    <b>L</b>a parcela debe estar siempre bien regada.
                    <br />
                    <b>N</b>o se recomienda la aplicación de productos fitorreguladores en parcelas que
                    no tengan un estado de salud óptimo.
                    <br />
                    <b>N</b>o llevar restos de otros tratamientos en el tanque, vaciarlo completamente.
                    <br />
                    <b>N</b>o verter los productos hasta que la cuba tenga 500 litros de agua.
                    <br />
                    <b>U</b>na vez con los 500 litros y el agitador en marcha verter los productos.
                    <br />
                    <b>D</b>isolver los polvos previamente en un cubo con agua para luego introducirlos en la cuba.
                    <br />
                    <b>N</b>o mezclar varios productos en el cubo.
                    <br />
                    <b>N</b>o quitar nunca la cestilla con la rejilla.
                    <br />
                    <b>L</b>levar las boquillas en buen estado, comprobarlo antes de iniciar el tratamiento.
                    <br />
                    <b>V</b>erificar la agitación.
                    <br />
                    <b>D</b>epositar los envases una vez vaciados en el punto <span
                        style="color: #76923c;">SIGFITO</span>
                    de esta empresa.
                </div>

                <p class="mb-2 font-weight-bold" style="
                    font-style: italic;
                    font-size: 12pt;
                    font-family: 'Times New Roman', Times, serif;
                    text-decoration: underline;
                    color: #ff0000;">
                    OBSERVACIONES Y RECOMENDACIONES IMPORTANTES <b>(RADICULAR)</b>:
                </p>

                <div style="font-family: Arial, Helvetica, sans-serif; font-size: 10pt;">
                    LEER SIEMPRE LAS ETIQUETAS ANTES DEL USO DE LOS PRODUCTOS RECOMENDADOS.
                    <br />
                    <br />
                    <b>N</b>o sobrepasar las dosis recomendadas sin consultar al servicio técnico de esta empresa.
                    <br />
                    <b>N</b>o llenar los depósitos con restos anteriores.
                    <br />
                    <b>L</b>lenar siempre la mitad del depósito antes de aplicar los fertilizantes.
                    <br />
                    <b>D</b>isolver los polvos previamente en un cubo de agua.
                    <br />
                    <b>D</b>epositar los envases una vez vaciados en el punto SIGFITO de esta empresa.
                    <br />
                    <br />
                </div>

                <div class="pl-1 mb-2 w-100" style="
                    border: 1px solid #0c0c0c;
                    font-size: 10pt;
                    font-family: Arial, Helvetica, sans-serif;">
                    <b>S</b>e dispondrán de todas las medidas de Seguridad e Higiene en el trabajo según la normativa
                    vigente.
                </div>

                <div *ngIf="appName !=='fotoagricultura'" class="d-flex mt-2" style="
                    font-size: 12pt;
                    font-family: 'Times New Roman', Times, serif;
                    font-style: italic;
                    color: #008000;">
                    <div style="flex: 2;">
                        PRECIO DE COSTE POR CUBA DE 1.000,00
                    </div>
                    <div style="flex: 1">
                        <b>{{receta.coste_tratamiento}}</b> € iva incluido.
                    </div>
                </div>
            </div>


        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>

            <div class="telegram_passport" *ngIf="showEnviarTelegram && currentUser.nombre === 'Inma'">
                <div *ngif="tooltipForce" class="telegram-passport-tooltip-wrap tooltip-top"><a id="tooltip" class="{{tooltipClass}}" href="https://telegram.org/dl/" target="_blank">Asegurate de tener Telegram instalado en tu equipo.</a></div>
                <button id="telegram_passport_auth" (click)="enviarTelegram();">
                <img style="width: 24px; height: 24px" src="./assets/telegram_48x48.png" alt="Icono Telegram">
                    Enviar Telegram
                </button>
            </div>

             <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
    <br>

</p-dialog>

<!-- VERSIÓN 'BASIC - BUITECH'-->
<p-dialog *ngIf="showRecetaBuitech" header="Receta de Tratamiento" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">
            <div class="mb-4 px-3 flex-row" style="font-size: 1.1rem;">
                <img *ngIf="appName === 'buitech'" src="assets/logo_agrogest.png" style="width: 150px; height: auto;" alt="Logo AgroGEST"/>
                <img *ngIf="appName === 'brio'" src="assets/logo_brio.png" style="width: 150px; height: auto;" alt="Logo Brio"/>
                <div *ngIf="appName === 'esparragosgranada'" style="font-size: 1.2rem;" alt="Logo Esparragos Granada">
                    {{receta.cliente?.cooperativa}}
                </div>
                <div>
                    <p>Titular: {{receta.cliente_nombre}}</p>
                </div>
                <div>
                    <p>Finca: {{receta.finca}}</p>
                </div>
                <div style="text-align: right">
                    <p>(Año/nº registro: {{receta.anyo}}/{{receta.id}})</p>
                </div>
            </div>
            <div>
                <p>CONTROL DE PLAGAS, ENFERMEDADES, MALAS HIERBAS Y FISIOPATÍAS</p>
                <p style="margin-left: 1rem;">Tratamientos Fitosanitarios</p>
                <table class="registro-table" border="1">
                    <thead>
                        <th style="width: 8%">Nº&nbsp;registro</th>
                        <th style="width: 18%">Producto</th>
                        <th style="width: 12%">Materia activa</th>
                        <th style="width: 8%">P.S.</th>
                        <th style="width: 12%">Motivo</th>
                        <th style="width: 8%">Dosis</th>
                        <th style="width: 10%">pH caldo</th>
                        <th style="width: 12%">Volumen caldo</th>
                        <th style="width: 12%">Eficacia</th>
                    </thead>
                    <tr *ngFor="let linea of (receta?.tratamiento?.linea || []); let i = index">
                        <td>{{linea?.producto?.num_registro}}</td>
                        <td>{{linea?.producto?.nombre}}</td>
                        <td>{{linea?.producto?.materia_activa}}</td>
                        <td>{{linea?.producto?.ps}}</td>
                        <td *ngIf="linea?.uso_agente?.agente">
                            {{linea?.uso_agente?.agente}}
                        </td>
                        <td *ngIf="!linea?.uso_agente?.agente && receta?.motivo">
                            {{receta.motivo}}
                        </td>
                        <td *ngIf="!linea?.uso_agente?.agente && !receta?.motivo">
                            {{receta.tratamiento_nombre}}
                        </td>
                        <td>
                            <span *ngIf="linea?.cantidad_cuaderno">
                                {{decimalFormat(linea?.cantidad_cuaderno, 2, '.', ',', 3) + ' ' +
                                receta.tratamiento?.uds_medida || ''}}
                            </span>
                        </td>
                        <td>{{receta.ph_caldo}}</td>
                        <td>{{receta.litros}}</td>
                        <td>{{receta.eficacia}}</td>
                    </tr>
                </table>

                <br>

                <p class="mx-3 d-flex justify-content-between">
                    <span>Parcelación tratamientos</span>
                    <span>Superficie cultivada: {{receta.superficie_parcelas || '0.00'}} Ha</span>
                </p>

                <table class="registro-table" border="1">
                    <thead>
                        <th style="width: 13%">{{sectorName}}</th>
                        <th style="width: 13%">{{parcelaName + '/s'}}</th>
                        <th style="width: 16%" class="dark-bg">Fecha fin del tratamiento</th>
                        <th style="width: 16%" class="dark-bg">Fin plazo seguridad</th>
                        <th style="width: 14%">Firma ejecución</th>
                        <th style="width: 14%">Código aplicador/es</th>
                        <th style="width: 14%">Código maquinaria</th>
                    </thead>
                    <tr>
                        <td>{{receta.sector}}</td>
                        <td>
                            <ul *ngIf="receta?.parcelas" class="parcelas-list">
                                <li *ngFor="let parcela of receta.parcelas; let i = index">
                                    {{parcela?.nombre}}
                                    <span *ngIf="i < (receta?.parcelas || []).length - 1">, </span>
                                </li>
                            </ul>
                        </td>
                        <td class="gray-bg">{{receta.fecha_fin}}</td>
                        <td class="gray-bg">{{getFinPlazoSeguridad()}}</td>
                        <td style="height: 3rem">
                            <div *ngIf="showRecetaFirmas && showTrabajadoresFirma">
                                <div class="canvas-wrap" style="width: 140px; height: 80px;">
                                    <button data-html2canvas-ignore="true" class="p-1 btn btn-link"
                                        (click)="clearSigPad(signaturePadElementTrabajadores)">Borrar</button>
                                    <app-signature #spTrabajadores
                                        (onEndEvent)="drawComplete(signaturePadElementTrabajadores)"></app-signature>
                                </div>
                            </div>
                        </td>
                        <td>{{receta.ids_trabajadores}}</td>
                        <td>{{receta.id_maquinaria}}</td>
                    </tr>
                </table>

                <br>

                <table border="1" class="registro-table">
                    <thead>
                        <th style="width: 45%" class="smaller">Firma del encargado:</th>
                        <th style="width: 25%" class="smaller">Firma y nº ROPO del técnico asesor:</th>
                        <th style="width: 30%; visibility: hidden; border: none !important;"></th>
                    </thead>
                    <tr>
                        <td style="vertical-align: top" class="smaller">
                            <div>
                                Yo, {{receta.cliente_nombre}}
                                <span *ngIf="receta?.cliente_nif">con NIF {{receta.cliente_nif}}</span>
                                recibo esta instrucción y me comprometo a su ejecución, informando puntualmente al
                                técnico asesor en caso de no poder llevarla a cabo según lo indicado.
                            </div>
                            <div *ngIf="showRecetaFirmas && showClientesFirma">
                                <div class="canvas-wrap">
                                    <button data-html2canvas-ignore="true" class="p-1 btn btn-link"
                                        (click)="clearSigPad(signaturePadElementClientes)">Borrar</button>
                                    <app-signature #spCliente
                                        (onEndEvent)="drawComplete(signaturePadElementClientes)"></app-signature>
                                </div>
                            </div>
                        </td>
                        <td style="height: 6.5rem" class="smaller">
                            <div style="height: 100%;" class="d-flex flex-column justify-content-between">
                                <div>
                                    NIF: {{receta.usuario_nif}}
                                    <br>
                                    Nº ROPO: {{receta.usuario_ropo}}
                                </div>
                                <div *ngIf="showRecetaFirmas && showUsuariosFirma">
                                    <div *ngIf="firmaPath">
                                        <img [src]="firmaPath" style="width: 140px; height: 80px;" class="info-firma-img" alt="Firma">
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td style="visibility: hidden; border: none !important;"></td>
                    </tr>
                </table>
            </div>

            <p *ngIf="appName === 'esparragosgranada'">
                Temperatura (23º - 29º), viento (leve) y {{receta.soleado}}.
            </p>

        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Logo WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
</p-dialog>


<!-- VERSIÓN "Laplana" -->
<p-dialog *ngIf="showRecetaLaplana" header="Generar Receta" [(visible)]="showReceta" (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv">
            <table class="table-laplana">
                <tr>
                    <td rowspan="3" class="w-25 text-center">
                        Nº&nbsp;ORDEN:
                        <br>
                        <b class="fs-big">#{{receta.id}}</b>
                    </td>
                    <td rowspan="3" class="w-25">
                        <p>
                            <b>NOMBRE DE LA FINCA</b>
                            <br>
                            {{receta.finca}}
                        </p>
                        <p>
                            <b>PROPIETARIO:</b>
                            <br>
                            {{receta.cliente_nombre}}
                        </p>
                        <p>
                            <b>SITUACIÓN GEOGRÁFICA:</b>
                            <br>
                            {{receta.finca_localidad}}
                        </p>
                    </td>
                    <td class="w-50 text-center font-weight-bold fs-big">
                        FITOSANITARIOS Y ABONADO FOLIAR
                    </td>
                </tr>
                <tr>
                    <td class="text-center font-weight-bold fs-big">
                        ORDEN DE TRATAMIENTOS
                        <br>
                        REGISTRO DE APLICACIÓN
                    </td>
                </tr>
                <tr>
                    <td class="small text-center">
                        Rev 4. {{receta.fecha_inicio}}
                    </td>
                </tr>
            </table>

            <br>

            <table class="table-laplana">
                <tr>
                    <td colspan="2">
                        <b>Fecha recomendación:</b>
                        {{receta.fecha_inicio}}
                    </td>
                    <td colspan="2" rowspan="2" class="text-center">
                        <small>Condiciones climáticas:</small>
                        <br>
                        <div class="d-flex justify-content-around">
                            <img src="assets/laplana/soleado.jpg" style="width: 24px; height: auto;" alt="Soleado">
                            <img src="assets/laplana/nublado.jpg" style="width: 24px; height: auto;" alt="Nublado">
                            <img src="assets/laplana/viento.jpg" style="width: 24px; height: auto;" alt="Viento">
                            <img src="assets/laplana/lluvia.jpg" style="width: 24px; height: auto;" alt="Lluvia">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <b>HORA A TRATAR:</b>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <b>Variedad</b>
                        {{receta.parcelas?.[0]?.cultivo}} "{{receta.parcelas?.[0]?.variedad}}"
                    </td>
                    <td>
                        <b>Superficie {{textoSuperficie}}:</b>
                        {{receta.superficie_parcelas}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Polígono:</b>
                        {{receta.parcelas?.[0]?.poligono}}
                    </td>
                    <td colspan="3">
                        <b>Parcela (Recinto): </b>
                        {{receta.parcelas?.[0]?.recinto}}
                        <br>
                        <b>Nº Parcela: </b>
                        {{receta.parcelas?.[0]?.numero}}
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <b>Maquinaria:</b>spCliente
                        {{receta.maquinaria}}
                    </td>
                    <td>
                        <b>Tractor:</b>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <b>PRESIÓN DE TRABAJO:</b>
                        {{receta.presion || 0}} atm
                    </td>
                    <td>
                        <b>VELOCIDAD DE AVANCE:</b>
                        {{receta.velocidad || 0}} Km/h
                    </td>
                    <td>
                        <b>LITROS POR HECTÁREA:</b>
                        {{receta.litros}}
                    </td>
                </tr>
            </table>

            <br>

            <table class="table-laplana">
                <thead>
                    <th>Plaga/Enfermedad/Deficiencia</th>
                    <th>Marca&nbsp;comercial</th>
                    <th>Materia&nbsp;activa</th>
                    <th>Dosis&nbsp;x&nbsp;1000&nbsp;L</th>
                    <th>Nº&nbsp;Reg.</th>
                    <th>Plazo&nbsp;de&nbsp;seguridad</th>
                </thead>
                <tr *ngFor="let linea of receta.tratamiento?.linea; let i = index">
                    <td>{{linea?.uso_agente?.agente}}</td>
                    <td>{{linea?.producto?.nombre}}</td>
                    <td>{{linea?.producto?.materia_activa}}</td>
                    <td>{{linea?.cantidad_real}}&nbsp;{{linea?.producto?.unidades}}</td>
                    <td>{{linea?.producto?.num_registro}}</td>
                    <td>{{linea?.producto?.ps || '-'}} días</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <b>Caldo aplicado:</b>
                        {{receta.caldo_aplicado}}
                    </td>
                    <td colspan="4">
                        <b>Sobrante:</b>
                    </td>
                </tr>
            </table>

            <br>

            <table class="table-laplana">
                <tr>
                    <td colspan="4"><b><u>OBSERVACIONES:</u></b> {{receta.observaciones}}</td>
                </tr>
                <tr>
                    <td colspan="4">
                        - Obligatorio el uso del equipo protector de riesgos químicos.
                        <br>
                        - Los sobrantes de caldo y/ó los lavados de tanque deben ser:
                        <br>
                        · Pulverizados sobre una parte sin tratar del cultivo y anotar a continuación la zona tratada:
                        <br>
                        · Pulverizados sobre los mismos lindes del cultivo, diluido al 50% y anotar la zona doblemente
                        tratada a continuación:
                        <br>
                        · Depositados en el tanque de evaporación de sobrantes de caldos.
                        <br>
                        A los envases vacios se realizara el triple enjuagado y se depositaran en el lugar indicado.
                    </td>
                </tr>
                <tr>
                    <td [attr.rowspan]="((receta.trabajadores || []).length + 1) || 1">
                        <b>Nombre del Técnico responsable:</b>
                        <br>
                        {{currentUser.nombre}} - {{currentUser.ropo}}

                    </td>
                    <td>
                        <b>Nombre de los operarios:</b>
                    </td>
                    <td>
                        <b>Horas:</b>
                    </td>
                    <td [attr.rowspan]="((receta.trabajadores || []).length + 1) || 1">
                        <b>Fecha aplicación:</b>
                        <br>
                        {{receta.fecha_fin}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div *ngFor="let trabajador of receta?.trabajadores || []; let i = index">
                            <span>{{trabajador?.nombre + ' ' + trabajador?.apellidos}} - {{trabajador?.ropo}} </span>

                            <hr *ngIf="i !== (receta?.trabajadores || []).length - 1">
                        </div>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="font-weight-bold">
                        PLAZO DE REENTRADA A LA FINCA: 24 HORAS
                    </td>
                </tr>
            </table>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>
    <br>

</p-dialog>


<!-- VERSIÓN "VGiner" -->
<p-dialog *ngIf="showRecetaVGiner" header="Generar Receta" [(visible)]="showReceta" (onShow)="scrollToTop()">

    <div *ngIf="showReceta" style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="w-100" style="font-size: 14px;">
            <!-- Cabecera -->
            <div class="w-100 d-flex justify-content-between">
                <!-- Bloque izquierdo -->
                <div class="pr-1" style="flex: 3">
                    <!-- Logo y título -->
                    <div class="mb-4 d-flex justify-content-around">
                        <div>
                            <!-- <b><u>{{receta?.finca_asociada?.razon_social || receta?.finca_asociada?.nombre || '(Sin titular asignado)'}}</u></b> -->
                            <img src="assets/logo_citricos_ginero_horizontal.png" style="width: 250px; height: auto;"
                                alt="Vicente Giner" />
                        </div>
                        <span class="font-weight-bold text-center" style="font-size: 25px;">
                            FICHA DE TRATAMIENTOS
                            <br>
                            FITOSANITARIOS
                        </span>
                    </div>
                    <!-- Tabla de datos principales -->
                    <div>
                        <div class="pt-1 d-flex justify-content-between" style="border-bottom: 1px solid #0c0c0c;">
                            <span style="flex: 2">CAMPAÑA CITRÍCOLA:</span>
                            <span style="flex: 2">{{receta.campanya_citricola}}</span>
                            <span style="flex: 3">FECHA DE RECEPCIÓN: {{receta.fecha_inicio}}</span>
                        </div>
                        <div class="pt-1 d-flex justify-content-between" style="border-bottom: 1px solid #0c0c0c;">
                            <span style="flex: 2">PROPIETARIO:</span>
                            <!--  <span style="flex: 2" *ngIf="!(appName==='vginer')">{{receta?.finca_asociada?.titular_nombre}}</span> -->
                            <span style="flex: 2">{{receta.finca_asociada?.razon_social}}</span>
                            <span style="flex: 3"></span>
                        </div>
                        <div class="pt-1 d-flex justify-content-between" style="border-bottom: 1px solid #0c0c0c;">
                            <span style="flex: 2">TÉRMINO MUNICIPAL:</span>
                            <span style="flex: 2">{{receta.finca_asociada?.localidad}}</span>
                            <span style="flex: 3">PATRÓN: {{splitFromArrayFormat(receta.parcelas ?? [], 'patron', ', ',
                                true)}}</span>
                        </div>
                        <div class="pt-1 d-flex justify-content-between" style="border-bottom: 1px solid #0c0c0c;">
                            <span style="flex: 2">PARTIDA:</span>
                            <span style="flex: 2">{{receta.finca}}</span>
                            <span style="flex: 3">VARIEDAD: {{splitFromArrayFormat(receta.parcelas ?? [], 'variedad', ', ',
                                true)}}</span>
                        </div>
                        <div class="pt-1 d-flex justify-content-between" style="border-bottom: 1px solid #0c0c0c;">
                            <span style="flex: 3">SUPERFICIE (en hanegadas (Hg) o hectáreas (Ha)):</span>
                            <span style="flex: 1">{{decimalFormat(receta.superficie_parcelas, 2)}}</span>
                            <span style="flex: 3">CÓDIGO TRAZABILIDAD: {{splitFromArrayFormat(receta.parcelas ?? [],
                                'codigo_trazabilidad', ', ', true)}}</span>
                        </div>
                    </div>
                </div>
                <!-- Bloque derecho -->
                <div class="pl-1" style="flex: 1">
                    <div class="py-1 d-flex justify-content-between">
                        <span>TÉCNICO:</span>
                        <span>APPCC-RG-10-02</span>
                    </div>
                    <div class="py-1">
                        <span style="border-bottom: 1px solid #0c0c0c;">
                            {{receta.usuario_nombre}}
                        </span>
                    </div>
                    <div class="py-1 d-flex justify-content-between">
                        <span style="flex: 2">
                            TIPO DE RIEGO:
                        </span>
                        <div style="flex: 2">
                            <div class="d-flex justify-content-between">
                                <span>A&nbsp;MANTA:</span>
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"></div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span>A&nbsp;GOTEO:</span>
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tabla con los productos -->
            <table class="table-vginer mt-4 text-center" style="border: 2px solid #0c0c0c;">
                <tr style="border-bottom: none;">
                    <td colspan="3" style="border-bottom: 2px solid #0c0c0c;">Fecha de la aplicación</td>
                    <td rowspan="2">Nº de registro fitosanitario</td>
                    <td rowspan="2">Nombre comercial</td>
                    <td rowspan="2">Materia activa</td>
                    <td rowspan="2">Dosis&nbsp;(%)</td>
                    <td rowspan="2">Volumen aplicado (lts/Ha)</td>
                    <td rowspan="2">Motivo tratamiento (piojo, araña, pulgón, mosca, hongos, malas hierbas, etc.)</td>
                    <td rowspan="2">Plazo de seguridad (días)</td>
                </tr>
                <tr style="border-bottom: 2px solid #0c0c0c;">
                    <td>Día</td>
                    <td>Mes</td>
                    <td>Año</td>
                </tr>
                <ng-container *ngFor="let linea of receta?.tratamiento?.linea; let i = index">
                    <tr *ngIf="![1, '1', true, 'true'].includes(linea?.ocultar_en_receta ?? false)" style="height: 40px;">
                        <td>{{receta.fecha_objeto?.dia}}</td>
                        <td>{{receta.fecha_objeto?.mes}}</td>
                        <td>{{receta.fecha_objeto?.anyo}}</td>
                        <td>{{linea?.producto?.num_registro}}</td>
                        <td>{{linea?.producto?.nombre}}</td>
                        <td>{{linea?.producto?.materia_activa}}</td>
                        <td>{{decimalFormat(linea?.cantidad_cuaderno, 2)}}</td>
                        <td>{{decimalFormat(receta.litros)}}</td>
                        <td>{{linea?.uso_agente?.agente}}</td>
                        <td>{{linea?.producto?.ps}}</td>
                    </tr>
                    <tr *ngIf="![1, '1', true, 'true'].includes(linea?.ocultar_en_receta ?? false)" style="height: 40px;">
                        <td *ngFor="let j of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"></td>
                    </tr>
                </ng-container>
                <tr>
                    <td colspan="10" class="text-left pl-1 pt-2 pb-4">
                        <b>COMPROMISO DEL AGRICULTOR:</b>
                        <br>
                        <p>
                            - Como proveedores de Cítricos Ginero S.L. de productos cítricos, conocemos la
                            legislación respecto al uso de productos fitosanitarios en España y en Europa.
                            <br>
                            - Nos comprometemos a aplicar sólo los productos autorizados para el cultivo de los
                            cítricos.
                            <br>
                            Y para que conste, firma la presente en {{receta.finca_asociada?.localidad ||
                            '_____________________'}}
                            a {{receta.fecha_hoy}}.
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            FIRMA:
                        </p>
                    </td>
                </tr>
            </table>
            <div class="mb-5 px-1 d-flex justify-content-between" style="font-size: 10px;">
                <span>Ver instrucciones al dorso.</span>
                <span>Versión 03/08</span>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
            <button *ngIf="showEnviarWhatsapp"
                [disabled]="(this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user'"
                class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreo"
                [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(receta.finca_asociada?.email || receta.cliente_email) || ((this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') && this.currentUser.rol === 'user')"
                (click)="enviarCorreo()" class="btn mx-2"
                [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container
                    *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la receta a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar a {{receta.finca_asociada?.email ? receta.finca_asociada?.email :
                        receta.cliente_email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(receta?.finca_asociada?.email || receta?.cliente_email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container
                    *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
        </div>
    </div>

</p-dialog>


<!-- Global GAP (VGiner) -->
<p-dialog *ngIf="showRecetaGlobalGAP" header="Generar Receta" [(visible)]="isShowingGlobalGAP"
    (onShow)="scrollToTop()">

    <div *ngIf="isShowingGlobalGAP"
        style="margin-left: 20px; margin-right: 20px; width: 80vw !important; height: 60vh;">
        <div id="html2canvasDiv" class="w-100" style="font-size: 12px;">
            <table class="w-100">
                <tr>
                    <td colspan="2">
                        <span style="font-style: 11px;">
                            <b><u>{{receta.finca_asociada?.razon_social || receta.finca_asociada?.titular_nombre ||
                                    '(Sin titular asignado)'}}</u></b>
                        </span>
                    </td>
                    <td colspan="6">
                        <span style="font-size: 13px;">
                            <b><u>HOJA DE TRATAMIENTOS FITOSANITARIOS </u></b>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="py-2" colspan="2">
                        <b>RECOMENDACIÓN TÉCNICA:</b>
                    </td>
                    <td colspan="6">
                        I.T. nº {{receta.it}}/{{receta.finca_asociada?.codigo}}
                    </td>
                </tr>
                <tr class="mb-3">
                    <td colspan="2">
                        <div class="pt-3 pb-1" style="border: 2px solid #0c0c0c;">
                            <b>
                                <u>Nombre {{fincaName}}:</u>
                                <br>
                                {{receta.finca}}
                            </b>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="d-flex justify-content-end">
                            <div class="w-75 pt-3 pb-1" style="border: 2px solid #0c0c0c;">
                                <div class="d-flex justify-content-between">
                                    <span style="flex: 1">
                                        <span class="mr-2">
                                            <b><u>Referencia {{parcelasName}}:</u></b>
                                        </span>
                                        <span>
                                            {{splitFromArrayFormat(receta.parcelas ?? [], 'nombre', ', ',
                                            true)}}
                                        </span>
                                    </span>
                                    <span style="flex: 1">
                                        <span class="mr-2">
                                            <b><u>Superficie tratada:</u></b>
                                        </span>
                                        <span>
                                            {{decimalFormat(receta.superficie_parcelas, 2)}} Ha.
                                        </span>
                                    </span>
                                </div>
                                <div class="d-flex">
                                    <span style="flex: 1">
                                        <span class="mr-2">
                                            <b><u>Variedades:</u></b>
                                        </span>
                                        <span>
                                            {{splitFromArrayFormat(receta.parcelas ?? [], 'variedad', ', ', true)}}
                                        </span>
                                    </span>
                                    <span style="flex: 1">
                                        <span class="mr-2">
                                            <b><u>Cultivo:</u></b>
                                        </span>
                                        <span>
                                            {{splitFromArrayFormat(receta.parcelas ?? [], 'cultivo', ', ', true)}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="py-2" colspan="8"></td>
                </tr>
                <tr class="tr-bordered-children text-center" style="border-top: 2px solid #0c0c0c;">
                    <td>Nº Registro</td>
                    <td>Producto (Nombre)</td>
                    <td>Mat. Activa.</td>
                    <td>P.S. Días</td>
                    <td>Plaga/enfermedad/Carencia/...</td>
                    <td>Dosis %</td>
                    <td>Dosis L-Kg/Cuba</td>
                    <td>cc-gr/mochila</td>
                </tr>
                <ng-container *ngFor="let linea of receta?.tratamiento?.linea; let i = index">
                    <tr *ngIf="![1, '1', true, 'true'].includes(linea?.ocultar_en_receta ?? false)"
                        class="tr-bordered-children text-center"
                        [style.borderBottom]="i === ((receta.tratamiento?.linea || []).length - 1) ? '2px solid #0c0c0c' : ''">
                        <td>{{linea?.producto?.num_registro}}</td>
                        <td>{{linea?.producto?.nombre}}</td>
                        <td>{{linea?.producto?.materia_activa}}</td>
                        <td>{{linea?.producto?.ps}}</td>
                        <td>{{linea?.uso_agente?.agente}}</td>
                        <td>{{decimalFormat(linea?.cantidad_real, digitosDecimalesDosis)}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </ng-container>

                <tr>
                    <td class="py-2" colspan="8"></td>
                </tr>
                <tr style="border: 2px solid #0c0c0c">
                    <td colspan="2">
                        <b>Presión (Atm):</b> {{receta.presion}}
                        <br>
                        <b>Velocidad (Km/h):</b> {{decimalFormat(+(receta.velocidad || 0), 2)}}
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{decimalFormat(receta.litros)}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Lts/Ha (Lts caldo/Ha)</b>
                    </td>
                    <td colspan="6" style="border-left: 2px solid #0c0c0c; border-right: 2px solid #0c0c0c">
                        <br>
                        <div class="mr-5 d-flex justify-content-between">
                            <span><b>Tipo de cuba: </b> {{receta.maquinaria || '__________'}}</span>
                            <span><b>Capacidad cuba: </b> {{decimalFormat(receta.capacidad_maquina) || '______'}}
                                Lts.</span>
                        </div>
                        <div class="mr-5 d-flex justify-content-between">
                            <b>Forma de tratamiento:</b>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                    border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.tipo_maquina === 'Pistolete'">x</span>
                                </div>
                                <span>Pistolete</span>
                            </div>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                    border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.tipo_maquina === 'Turboatomizador'">x</span>
                                </div>
                                <span>Turboatomizador</span>
                            </div>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                    border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.tipo_maquina === 'Mochila'">x</span>
                                </div>
                                <span>Mochila</span>
                            </div>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                    border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.tipo_maquina === 'Parcheo'">x</span>
                                </div>
                                <span>Parcheo</span>
                            </div>
                            <div>
                                <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                    border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <!-- <span>x</span> -->
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="py-2" colspan="8"></td>
                </tr>
                <tr style="border: 2px solid #0c0c0c; border-bottom: none;">
                    <td colspan="6">
                        <b>INSTRUCCIONES / OBSERVACIONES</b>
                        <br>
                        {{receta.observaciones}}
                        <br>
                        <br>
                        <div class="mr-5 d-flex justify-content-between">
                            <div class="mr-3 small">
                                CONDICIONES METEOROLÓGICAS DURANTE EL TRATAMIENTO:
                            </div>
                            <div class="mr-3">
                                <b>Tª:</b>
                                {{receta.temperatura}}
                            </div>
                            <div class="mr-3">
                                H.R:
                                {{receta.hr}}
                            </div>
                            <div>
                                <b>VIENTO:</b>
                                {{receta.viento}}
                            </div>
                        </div>
                    </td>
                    <td colspan="2" style="border-right: 2px solid #0c0c0c">
                        <div class="mb-2 d-flex">
                            <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                border: 2px solid #0c0c0c;" class="d-flex justify-content-center align-items-center">
                                <span *ngIf="receta?.clima === 'Cubierto'">x</span>
                            </div>
                            <span>Cubierto</span>
                        </div>
                        <div class="mb-2 d-flex">
                            <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                border: 2px solid #0c0c0c;" class="d-flex justify-content-center align-items-center">
                                <span *ngIf="receta?.clima === 'Semidespejado'">x</span>
                            </div>
                            <span>Semicubierto</span>
                        </div>
                        <div class="mb-2 d-flex">
                            <div style="width: 20px; height: 20px; padding: 2px; margin-right: 4px;
                                border: 2px solid #0c0c0c;" class="d-flex justify-content-center align-items-center">
                                <span *ngIf="receta?.clima === 'Despejado'">x</span>
                            </div>
                            <span>Despejado</span>
                        </div>
                    </td>
                </tr>
                <tr style="border: 2px solid #0c0c0c; border-top: none;">
                    <td colspan="8" style="border-right: 2px solid #0c0c0c;">
                        PRECAUCIONES: es de uso obligatorio el uso de ropa protectora cuando se manejen y apliquen
                        fitosanitarios.
                    </td>
                </tr>
                <tr>
                    <td class="py-2" colspan="8"></td>
                </tr>
                <tr style="border: 2px solid #0c0c0c;">
                    <td colspan="2" rowspan="2" style="border-right: 2px solid #0c0c0c;">
                        <div>
                            <u>Recomendación del Técnico:</u>
                            <br>
                            Fecha: {{receta.fecha_inicio}}
                            <br>
                            Firmado: {{receta.usuario_nombre}}
                            <br>
                            <div style="min-height: 4rem;">
                                <div *ngIf="showRecetaFirmas && showUsuariosFirma" class="canvas-wrap"
                                    style="width: 140px; height: 80px;">
                                    <div *ngIf="firmaPath" style="display: flex; flex-direction: column;">
                                        <img [src]="firmaPath" style="width: 140px; height: 80px;" class="info-firma-img" alt="Firma">
                                    </div>
                                </div>
                            </div>
                            Nº ROPO: {{receta.usuario_ropo}}
                        </div>
                    </td>
                    <td colspan="6" style="border-right: 2px solid #0c0c0c; border-bottom: 2px solid #0c0c0c">
                        <u>Confirmación de la aplicación del tratamiento</u>
                        <br>
                        <div class="w-100 d-flex justify-content-between">
                            <div class="d-flex justify-content-around">
                                <span class="mr-1">Número de Cubas / Litros Totales:</span>
                                <span class="mr-2">{{receta.cubas_totales || '___'}}&nbsp;Cuba/s</span>
                                <span>{{decimalFormat(receta.litros_aplicados_ha) || '______'}}&nbsp;Lts.</span>
                            </div>
                            <div class="mr-5 d-flex justify-content-between">
                                <span class="mr-2">Fecha viable de recolección:</span>
                                <span>{{receta.fecha_recoleccion || '__/__/____'}}</span>
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-between">
                            <div class="d-flex justify-content-center" style="flex: 1">
                                <span class="mr-2">Fecha inicio:</span>
                                <span>{{receta.fecha_inicio || '__/__/____'}} a las {{receta.hora_inicio ||
                                    '__:__'}}</span>
                            </div>
                            <div class="mr-5 d-flex justify-content-center" style="flex: 1">
                                <span class="mr-2">Fecha fin:</span>
                                <span>{{receta.fecha_fin || '__/__/____'}} a las {{receta.hora_fin || '__:__'}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style="border: 2px solid #0c0c0c;">
                    <td colspan="6">
                        Tratamiento con mezclas sobrantes de aplicaciones fitosanitarias. Forma de tratamiento: Vaciado
                        y limpieza cuba/s en el
                        <br>
                        <div class="py-3 d-flex justify-content-around">
                            <div class="d-flex">
                                <span class="mr-1">Cubeto evaporación </span>
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.cubeto_evaporacion">x</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <span class="mr-1">Punto carga </span>
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.punto_carga">x</span>
                                </div>
                            </div>
                            <div>
                                Litros caldo sobrante {{decimalFormat(+(receta.litros_sobrante || 0)) || 0}} Lts
                            </div>
                            <div>
                                Día tratamiento {{receta.dia_tratamiento}}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style="border: 2px solid #0c0c0c;">
                    <td colspan="2" rowspan="2" style="border-right: 2px solid #0c0c0c">
                        <div>
                            Firma Técnico (Validación Final):
                            <br>
                            <div style="min-height: 4rem;">
                                <div *ngIf="showRecetaFirmas && showUsuariosFirma" class="canvas-wrap"
                                    style="width: 140px; height: 80px;">
                                    <div *ngIf="firmaPath" style="display: flex; flex-direction: column;">
                                        <img [src]="firmaPath" style="width: 140px; height: 80px;" class="info-firma-img" alt="Firma">
                                    </div>
                                </div>
                            </div>
                            Nº ROPO: {{receta.usuario_ropo}}
                            <br>
                            Nombre: {{receta.usuario_nombre}}
                        </div>
                    </td>
                    <td colspan="6" style="border-right: 2px solid #0c0c0c">
                        Limpieza de Equipos Individuales de Protección (EPIs): Cada operario debe limpiar sus EPIs al
                        finalizar la aplicación.
                        <br>
                        <div class="d-flex justify-content-around">
                            <span>Limpieza de EPIs: </span>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="receta?.limpieza_epis">x</span>
                                </div>
                                <span class="ml-1">SÍ</span>
                            </div>
                            <div class="d-flex">
                                <div style="width: 20px; height: 20px; padding: 2px; border: 2px solid #0c0c0c;"
                                    class="d-flex justify-content-center align-items-center">
                                    <span *ngIf="!receta?.limpieza_epis">x</span>
                                </div>
                                <span class="ml-1">NO</span>
                            </div>
                            <span>Día de limpieza: {{receta.dia_limpieza || '__/__/____'}}</span>
                        </div>
                    </td>
                </tr>
                <tr style="border: 2px solid #0c0c0c">
                    <td colspan="2" style="border-right: 2px solid #0c0c0c">
                        Nombres operarios aplicadores:
                        <br>
                        {{receta.trabajadores_nombres}}
                    </td>
                    <td colspan="4" style="border-right: 2px solid #0c0c0c">
                        Firmas:
                    </td>
                </tr>
            </table>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="isShowingGlobalGAP = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirReceta();" [disabled]="isGeneratingCanvas">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
        </div>
    </div>

</p-dialog>


<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>
<!-- Modal filtrar campaña -->
<p-dialog [contentStyle]="{'min-height':'250px'}" header="Campaña" [closeOnEscape]="false" [closable]="false"
    [(visible)]="modalShowCampaign" (onShow)="scrollToTop()">

    <div>
        <div style="max-width: 200px;">
            <p-select [options]="years" [(ngModel)]="selectedYears" (onChange)='selectedCampaign()'></p-select>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="closeModalShowCampaign();">Cerrar</button>
        </div>
        <br>
    </div>
</p-dialog>