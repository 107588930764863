<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [cols]="cols"
          [filtering]="filter"
          [globalConditionalStyle]="globalConditionalStyle"
          [globalVisualTransform]="globalVisualTransform"
          [actionButtonText]="btnAnadir"
          (actionButtonClick)="add()">
    <ng-template #header>
      <div *ngIf="rol === 'admin'" class="d-flex justify-content-start align-content-center">
          <p-inputSwitch
                  [style]="{marginLeft: '15px', marginRight: '10px'}"
                  [(ngModel)]="showAllCampos"
                  (onChange)="changeShowAll();filterCampos()">
          </p-inputSwitch>
          <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todos</p>
      </div>
    </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                (editRowButtonEvent)="edit(data.data)"
                (deleteRowButtonEvent)="show('delete', data.data)"
                (showRowButtonEvent)="show('info', data.data)">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  *ngIf="canShow['info']"
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <h4>Datos del Campo</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosParcela">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos SigPAC</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosSigpac">
    </app-form-builder>
    <br>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
  *ngIf="canShow['delete']"
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <h4>Datos Parcela</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosParcela">
    </app-form-builder>
    <br>
    <br>
    <h4>Datos SigPAC</h4>
    <app-form-builder
            [model]="model"
            [distributionColumns]="4"
            [fields]="formFieldsDatosSigpac">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>