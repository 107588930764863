<p-panel>
    <h4>Datos del producto</h4>
    <div class="row">
        <app-form-builder *ngIf="!hasntRegisterNumber" [fields]="form.topFormFields" [distributionColumns]="4"
            (formChanges)="formChanges($event)" [model]="model">
        </app-form-builder>


        <app-form-builder *ngIf="hasntRegisterNumber" [fields]="form.topFormFieldsNoReg" [distributionColumns]="4"
            (formChanges)="formChanges($event)" [model]="model">
        </app-form-builder>


        <div *ngIf="this.appName==='agroleza'" class="col-sm-2 mb-5">
            <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*" />
            <div class="d-flex justify-content-center">
                <input type="button" class="btn"
                    style='border-radius: 5px;
                font-size: 1em;
                padding: 6px 12px;
                font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif; background-color: #27A745;color:white;' value="Añadir foto"
                    (click)="readUrl(adjuntarFoto)" />
            </div>
            <img [src]="serverUrl + 'fotos_productos/' + model.imagen" *ngIf="model?.imagen" width="100%"
                height="100%" alt="Foto Producto">
        </div>
    </div>
    <br>
    <div *ngIf="isProductSelected && !hasntRegisterNumber" class="row" style="
        background-color: lightgoldenrodyellow;
        border: 1px solid lightgray;
        margin-bottom: 20px;
        padding-top: 10px;
        box-shadow: 4px 4px 2px -2px rgba(0, 0, 0, 0.45);">

        <div class="col-sm-2">
            <span style="font-weight: bolder">
                Nº Registro
                <button *ngIf="form?.productos?.selected?.referencia" style="
                        font-size: small;
                        padding: 0;" class="btn btn-link btn-sm" (click)="goToVademecum(model.num_registro ?? 0)">
                    (Ver)
                </button>
            </span>

            <p style="margin-top: 4px">{{form.productos.selected?.referencia || '...'}}</p>
        </div>
        <div class="col-sm-2">
            <span style="font-weight: bold">Dosis</span>

            <div *ngIf="form?.agentes?.selected && form?.agentes?.selected?.dosis1">
                <p style="margin-top: 4px">
                    {{form.agentes.selected.dosis1}}
                    <span *ngIf="form?.agentes?.selected?.dosis2 && form?.agentes?.selected?.dosis2 !== '0'">
                        - {{form.agentes.selected.dosis2}}
                    </span>
                    {{form.agentes.selected.unidad_medida}}
                </p>
            </div>
            <div *ngIf="!form?.agentes?.selected || !form?.agentes?.selected?.dosis1">
                <p>...</p>
            </div>
        </div>
        <div class="col-sm-2">
            <span style="font-weight: bold">Plazo Seg.</span>
            <p style="margin-top: 4px">{{form.usos.selected?.plazo_seguridad || model['ps'] || '...'}}</p>
        </div>
        <div class="col-sm-2">
            <span style="font-weight: bold">F. Caducidad</span>
            <p style="margin-top: 4px">{{fecha_caducidad || '...'}}</p>
        </div>
        <div class="col-sm-2" *ngIf="showProductosMaxAplicacion">
            <span style="font-weight: bold">Nº Máx.Aplic.</span>
            <p style="margin-top: 4px">{{form.usos.selected.n_aplicaciones || '...'}}</p>
        </div>
        <div class="col-sm-4">
            <span style="font-weight: bold">Materia Activa</span>
            <p style="margin-top: 4px">{{model.materia_activa || '...'}}</p>
        </div>
        <div class="col-sm-12">
            <p *ngIf="form?.agentes?.selected?.forma_epoca ">{{form.agentes.selected.forma_epoca}}</p>
        </div>

    </div>
    <div class="row">
        <div class="col-sm-8">
            <app-form-builder [fields]="form.bottomFormFieldsLeft" [distributionColumns]="4"
                (formChanges)="formChanges($event)" [model]="model">
            </app-form-builder>
        </div>
    </div>
    <div class="row" style="margin-left: 1%;">
        <app-dictado *ngIf="this.appName === 'amoros'" mensaje="Grabar observaciones"
            [observaciones]="model.observaciones ?? ''" (sendObservaciones)="getObservaciones($event)"></app-dictado>
    </div>

    <br>
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
            <app-request-button [request]="request" (click)="submit()" [mode]="requestButtonType"
                [lockOnSuccess]="true">
            </app-request-button>
        </div>
    </div>
</p-panel>
<br>
<p-panel *ngIf="getType() === formTypes.CREATE && !hasntRegisterNumber">
    <app-common-list-table [request]="formApi.magramaProducto" [hideSearch]="true" (rowClick)="productSelected($event);"
        [minWidth]="800" [cols]="form.cols" [globalConditionalStyle]="globalConditionalStyle">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Ficha Técnica</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <a *ngIf="data.data"
                    [href]="'https://fichas-oficiales.s3.eu-west-3.amazonaws.com/' + data.data.referencia.trim() + '.pdf'"
                    target="_blank">
                    <button class="btn btn-primary">
                        <span class="fa fa-file" style="margin-right: 10px"></span>
                        Ficha
                    </button>
                    <!-- Notification -->
                    <div *ngIf="showInfoCambios && data.data.codigo_actualizacion"
                        class="d-flex justify-content-center align-items-center" style="
                            background-color: red;
                            height: 15px;
                            width: 15px;
                            position: relative;
                            top: -36px;
                            left: 68px;
                            border: 1px inset white;
                            border-radius: 50%;
                            color: white;
                            font-size: xx-small;">
                        <span>!</span>
                    </div>
                </a>
            </td>
        </ng-template>

    </app-common-list-table>
</p-panel>
<br>