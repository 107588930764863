<br>
<div id="leyendas">
    <button (click)="myFilter('fecha_inscripcion', 0, 12)" style="background: none; border: none">
        <input readonly type="text" class="l-green">
        <span>Autorizado recientemente</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 0, 6)" style="background: none; border: none">
        <input readonly type="text" class="l-orange">
        <span>Caducado de 0 a 6 meses</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 6, 12)" style="background: none; border: none">
        <input readonly type="text" class="l-red">
        <span>Caducado de 6 a 12 meses</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 12, 99999)" style="background: none; border: none">
        <input readonly type="text" class="l-dark">
        <span>Caducado hace más de 12 meses</span>
    </button>
    <div style="margin-left: auto;" *ngIf="updateVademecum">
        <p style="
        color: #bb1c1c;
        font-weight: 700;
        font-size: 0.8rem;
        margin: 0;">*Datos en proceso de actualización </p>

    </div>
</div>

<div *ngIf="!showSdiProductos" style="width: auto; overflow: auto">
    <app-common-list-table [request]="tableValues" [minWidth]="800" [cols]="cols"
        [globalConditionalStyle]="globalConditionalStyle" [globalVisualTransform]="globalVisualTransform"
        [actionButtonText]="canAddProductos ? 'Añadir Producto' : ''" (actionButtonClick)="addProducto()">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons [showNotification]="data.data.codigo_actualizacion" [hideEdit]="!canEditProductos"
                    [hideDelete]="!canDeleteProductos" (editRowButtonEvent)="edit(data.data)" [showDuplicate]="true" (duplicateRowButtonEvent)="duplicate(data.data)"
                    (deleteRowButtonEvent)="show('delete', data.data)" (showRowButtonEvent)="show('info', data.data)">
                </app-action-buttons>
            </td>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog 
    *ngIf="canShow['info']"
    header="Detalle del registro" 
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <div *ngIf="showInfoCambios && model['codigo_actualizacion']">
            <p style="margin-bottom: 0; color: red;">
                <i class="fa fa-exclamation-circle"></i>
                Ha habido cambios recientes (<b>{{model['fecha_actualizacion'] || 'Fecha desconocida'}}</b>)
            </p>
        </div>
        <button style="
                font-size: small;
                padding: 0;
                height: 0;" class="btn btn-link btn-sm" (click)="showDetalle(model)">
            Ver usos y agentes
        </button>
        <app-form-builder [fields]="formFields" [distributionColumns]="4" [distributionOrder]="[4,6]" [model]="model">
        </app-form-builder>
        <div class="row">
            <div class="col-12">
                <app-form-builder [fields]="formFieldBottomLeft" [distributionColumns]="4" [model]="model">
                </app-form-builder>
            </div>
            <!--             <div class="col-5">
                <form-builder
                        [fields]="formFieldBottomRight"
                        [distribution-columns]="3"
                        [model]="model">
                </form-builder>
            </div> -->
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog 
    *ngIf="canShow['delete']"
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()" 
    [modal]="true" 
    [closable]="false"
>
    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="4" [distributionOrder]="[4,6]" [model]="model">
        </app-form-builder>
        <div class="row">
            <div class="col-12">
                <app-form-builder [fields]="formFieldBottomLeft" [distributionColumns]="4" [model]="model">
                </app-form-builder>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
            style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>
</p-dialog>

<!-- Copiado de Vademecum -->
<p-dialog 
    *ngIf="canShow['detalle']"
    header="Información sobre usos y agentes" 
    [(visible)]="canShow['detalle']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 65vw !important; height: 60vh !important;">
        <h4>
            Producto: {{model['nombre']}} (Nº Registro: {{model['num_registro']}})
            <button class="btn btn-link btn-sm" style="font-size: small;" (click)="goToVademecum(model.num_registro ?? 0)">
                (Ver en Vademecum)
            </button>
        </h4>
        <br>
        <h4>Usos y Agentes</h4>
        <div class="row">
            <div class="col-2">
                Uso
            </div>
            <div class="col-2">
                Agente
            </div>
            <div class="col-1">
                Dosis Min
            </div>
            <div class="col-1">
                Dosis Max
            </div>
            <div class="col-1">
                Ud. medida
            </div>
            <div class="col-1">
                Plazo Seg.
            </div>
            <div class="col-1">
                Vol caldo
            </div>
            <div class="col-1">
                Nº aplicación
            </div>
            <div class="col-1">
                Intervalos
            </div>
        </div>
        <hr>

        <div style="height: 220px; overflow-y: auto; overflow-x: hidden" *ngIf="model['usos']">
            <div *ngFor="let uso of model['usos']">
                <div class="row" [style.color]="uso.codigo_actualizacion === 'USOAGENTE_DEL' ? '#a0a0a0' : 'initial'">
                    <!-- <div class="row"> -->
                    <div *ngIf="uso.codigo_actualizacion === 'USOAGENTE_DEL'" style="
                        border-bottom: 1px solid #ff9898;
                        position: relative;
                        top: 12px;
                        width: 100%;
                        margin: 0 auto;
                        z-index: 2;">
                    </div>
                    <div class="col-2">
                        {{uso.uso}}
                    </div>
                    <div class="col-2">
                        {{uso.agente}}
                    </div>
                    <div class="col-1" style="text-align: center;">
                        {{uso.dosis1}}
                        <b [style.visibility]="uso.codigo_actualizacion === 'DOSIS_MIN' ? 'visible' : 'hidden'"
                            style="color: red;">*</b>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        {{uso.dosis2}}
                        <b [style.visibility]="uso.codigo_actualizacion === 'DOSIS_MAX' ? 'visible' : 'hidden'"
                            style="color: red;">*</b>
                    </div>
                    <div class="col-1" style="text-align: left;">
                        {{uso.unidad_medida}}
                    </div>
                    <div class="col-1" style="text-align: left;">
                        {{uso.plazo_seguridad}}
                    </div>
                    <div class="col-1" style="text-align: left;">
                        {{uso?.vol_caldo}}
                    </div>
                    <div class="col-1" style="text-align: left;">
                        {{uso?.n_aplicaciones}}
                    </div>
                    <div class="col-1" style="text-align: left;">
                        {{uso?.intervalos}}
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="!model['usos']">Cargando...</div>
        <br>
        <div class="d-flex justify-content-center mb-2">
            <button class="btn btn-dark mx-2" (click)="hide('detalle')">Cerrar</button>
        </div>
    </div>
</p-dialog>

<!-- INTEGRACIÓN CON SDI -->
<div *ngIf="showSdiProductos" style="width: auto; overflow: auto">
    <app-common-list-table [request]="getRequestSdi" [cols]="colsSdi">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons (showRowButtonEvent)="show('infoSdi', data)" [hideEdit]="true" [hideDelete]="true">
                </app-action-buttons>
            </td>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog 
    *ngIf="canShow['infoSdi']"
    header="Detalle del registro" 
    [(visible)]="canShow['infoSdi']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFieldsSdi" [distributionColumns]="3" [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('infoSdi')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>