export class UserModel {
    [key: string]: string | number | boolean | null;
    id = '';
    nombre = '';
    sm?: boolean | null;
    usuario?: string;
    user?: string;
    estado?: string;
    apellidos = '';
    password = '';
    rol = '';
    username? = '';
    nif?: string | null;
    ropo?: string;
    pais?: string;
    fecha_caducidad?: string;
    tipo_carnet?: string;
    email?: string;
    telefono?: string;
    imei?: string;
    // MyL
    uds_medida?: string;
    ratio_ha?: number;
    id_finca?: number;
    // Innovia cruce entre usuarios y trabajador
    id_cliente?: string;
    tipo?: string;
    firma?: string;
    id_telegram?: number;
    acceso_plataforma?: string;
    sin_contraseña?: string;
    password_repeat?: string;
    password_old?: string;
    entidad?: string;
    cif?: string | null;

    constructor(username: string, password: string){
        this.username = username;
        this.password = password;
    }
}
