export class FormRequest {

    public state = '';

    private events: EventInterface = {
        load: {
            before: [],
            on: [],
            after: []
        },
        update: {
            before: [],
            on: [],
            after: []
        },
        send: {
            before: [],
            on: [],
            after: []
        },
        finish: {
            before: [],
            on: [],
            after: []
        }
    };

    // REQUISITES
    constructor() {

    }

    // EVENTS
    public beforeLoad(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.load.before.push(callback);
    }

    public onLoad(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.load.on.push(callback);
    }

    public afterLoad(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.load.after.push(callback);
    }

    public beforeUpdate(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.update.before.push(callback);
    }

    public onUpdate(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.update.on.push(callback);
    }

    public afterUpdate(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.update.after.push(callback);
    }

    public beforeSend(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.send.before.push(callback);
    }

    public onSend(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.send.on.push(callback);
    }

    public afterSend(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.send.after.push(callback);
    }

    public beforeFinish(callback:(resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.finish.before.push(callback);
    }

    public onFinish(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.finish.on.push(callback);
    }

    public afterFinish(callback: (resolve: (arg: boolean | null) => boolean | void) => void) {
        this.events.finish.after.push(callback);
    }
    
    // ROUTINES
    public load() {
        this.state = 'load-before';
        this.executeQueue(this.events.load.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'load-on';
                    return this.executeQueue(this.events.load.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                next = true;
                if (next) {
                    this.state = 'load-after';
                    return this.executeQueue(this.events.load.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Load Stack: ', err);
            });
    }

    public update() {
        this.state = 'update-before';
        this.executeQueue(this.events.update.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'update-on';
                    return this.executeQueue(this.events.update.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'update-after';
                    return this.executeQueue(this.events.update.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Update Stack: ', err);
            });
    }

    public send() {
        this.state = 'send-before';
        this.executeQueue(this.events.send.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'send-on';
                    return this.executeQueue(this.events.send.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'send-after';
                    return this.executeQueue(this.events.send.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Send Stack: ', err);
            });
    }

    public finish() {
        this.state = 'finish-before';
        this.executeQueue(this.events.finish.before.slice())
            .then((next) => {
                if (next) {
                    this.state = 'finish-on';
                    return this.executeQueue(this.events.finish.on.slice());
                } else {
                    return;
                }
            })
            .then((next) => {
                if (next) {
                    this.state = 'finish-after';
                    return this.executeQueue(this.events.finish.after.slice());
                } else {
                    return;
                }
            })
            .catch(err => {
                console.error('FormRequest @ Processing Finish Stack: ', err);
            });
    }

    private executeQueue(queue: ((resolve: (arg: boolean | null) => boolean | void) => void)[]): Promise<boolean | unknown> {
        return new Promise(queue[0] || ((resolve) => resolve(false)))
            .then((next) => {
                if (next === true) {
                    queue.shift();
                    if (queue.length > 0) {
                        return this.executeQueue(queue);
                    }
                    return true;
                } else if (next === false) {
                    return false;
                }
                return true;
            });
    }
    
    
}

export enum FormRequestTypes {
    CREATE, EDIT, DUPLICATE
}
interface EventInterface {
    load: {
        before: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        on: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        after: (((resolve: (arg: boolean | null) => boolean | void) => void))[]
    },
    update: {
        before: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        on: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        after: (((resolve: (arg: boolean | null) => boolean | void) => void))[]
    },
    send: {
        before:  (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        on: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        after: (((resolve: (arg: boolean | null) => boolean | void) => void))[]
    },
    finish: {
        before: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        on: (((resolve: (arg: boolean | null) => boolean | void) => void))[],
        after: (((resolve: (arg: boolean | null) => boolean | void) => void))[]
    }
};