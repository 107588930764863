<br />
<div style="width: auto; overflow: auto">
  <app-common-list-table
    [request]="getRequest"
    [filtering]="filter"
    [cols]="cols"
    [globalConditionalStyle]="globalConditionalStyle"
    [globalVisualTransform]="globalVisualTransform"
    [actionButtonText]="canEditTrabajadores ? addText : ''"
    (actionButtonClick)="add()"
  >
    <ng-template #header>
      <div *ngIf="this.appName === 'agropecuariaramirogruas'" style="margin-left: 10px; margin-right: 10px">
        <select (change)="filtrarTrabajadores($event)" name="propietarioTrabajador">
          <option [selected]="'Todos'" value="Todos">Todos</option>
          <option [selected]="'Propios'" value="Propios">Propios</option>
          <option [selected]="'Cliente'" value="Cliente">Cliente</option>
        </select>
      </div>
      <div *ngIf="showTrabajadoresCargos" class="text-right">
        <button
          (click)="show('cargos', null)"
          class="mx-1 btn btn-primary"
          style="padding: 4px 5px; font-size: 0.8em"
        >
          Cargos
        </button>
      </div>
    </ng-template>

    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center" style="margin-bottom: 0">
          Acciones
        </p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          (duplicateRowButtonEvent)="duplicate(data.data)"
          (editRowButtonEvent)="edit(data.data)"
          (deleteRowButtonEvent)="canEditTrabajadores ? show('delete', data.data) : null"
          (showRowButtonEvent)="show('info', data.data)"
          [hideEdit]="(applicationType === 'cropgest' && this.currentUser.tipo === 'comunero' && data.data.tipo_usuario === 'aplicador') || !canEditTrabajadores"
          [hideDelete]="(applicationType === 'cropgest' && this.currentUser.tipo === 'comunero' && data.data.tipo_usuario === 'aplicador') || !canEditTrabajadores"
        >
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  *ngIf="canShow['info']"
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()"
>
  <div style="width: 80vw !important">
    <app-form-builder
      [fields]="formFields"
      [distributionOrder]="distributionOrder"
      [distributionColumns]="4"
      [model]="model"
    >
    </app-form-builder>
    <div *ngIf="showTrabajadoresFirma" style="width: 300px; height: 150px">
      <h6>Firma</h6>
      <div *ngIf="model?.has_signature" class="canvas-wrap">
        <img
          [src]="
            this.pathToFirmas +
            '_' +
            this.componentName +
            '/' +
            (this.model.id || 0) +
            '.png'
          "
          style="width: 100%; height: auto"
          alt="Firma"
        />
      </div>
    </div>

    <br />
    <div *ngIf="imagenAdjunto" class="adjunto-container">
      <p>Foto</p>
      <button class="img-container" (click)="verAdjunto()">
        <img [src]="imagenAdjunto" alt="" />
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br />
</p-dialog>

<p-dialog 
  *ngIf="canShow['delete']"
  header="Eliminar registro" 
  [(visible)]="canShow['delete']" 
  (onShow)="scrollToTop()"
>
  <div style="width: 80vw !important">
    <app-form-builder
      [fields]="formFields"
      [distributionOrder]="distributionOrder"
      [distributionColumns]="4"
      [model]="model"
    >
    </app-form-builder>
    <br />
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
        (click)="deleteRegister()"
        [request]="deleteRequest"
        [mode]="deleteRequestButtonType"
      >
      </app-request-button>
    </div>
    <br />
    <p
      *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
      style="color: red; text-align: center"
    >
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br />
</p-dialog>

<p-dialog 
  *ngIf="canShow['cargos']"
  header="Cargos" 
  [(visible)]="canShow['cargos']" 
  (onShow)="scrollToTop()"
>
  <div style="max-height: 30vw; width: 60vw !important; overflow: scroll;">

    <div class="row">
      <div class="col-md-12" *ngIf="!showCostes">
        <app-common-list-table 
          [request]="getRequestCargos" 
          [hideSearch]="true"
          [cols]="colsCargos">
        </app-common-list-table>
      </div>
    </div>
    

    <p-treetable [value]="this.trabajadores" id="cargos-table"
      *ngIf="showCostes" #table_cargos>
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Precio</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr>
          <td pEditableColumn>
            <p-treeTableCellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="rowData.nombre" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.nombre }}
              </ng-template>
            </p-treeTableCellEditor>
          </td>
          <td pEditableColumn>
            <p-treeTableCellEditor id="{{ 'precioHora' + i }}">
              <ng-template pTemplate="input">
                <input type="number" [(ngModel)]="rowData.precio_hora" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.precio_hora }}
              </ng-template>
            </p-treeTableCellEditor>
          </td>
          <td>
            <!--   <button pButton type="button" icon="pi pi-pencil"  
                style="padding: 0 5px;
                border-radius: 3px;
                background-color: #ffc107;
                border: 1px solid #ffc107;
                margin-right: 10px;"  
                (click)="editCargo(i)"></button> -->
            <button pButton type="button" icon="pi pi-save" style="padding: 0 5px"
              (click)="updateCargo(rowData)"> </button>
            <button pButton type="button" icon="pi pi-trash" style="padding: 0 5px; background-color: red;"
              (click)="deleteCargo(rowData)"> </button>
          </td>
        </tr>
      </ng-template>
    </p-treetable>
    <div class="col mb-5">
      <h6>Añadir Cargo</h6>
      <div class="d-flex align-items-end"> <!-- Alinea los elementos en la parte inferior -->
         <div class="flex-grow-1 "> <!-- Utiliza toda la flexibilidad disponible -->
            <app-form-builder [model]="modelCargos" [undoEnabled]="undoEnabled" [distributionColumns]="2"
               [distributionOrder]="distributionOrder" [fields]="formFieldsCargos" (formChanges)="formChanges()">
            </app-form-builder>
         </div>
         <div  style="padding: 0 5px"> <!-- Agrega un margen izquierdo -->
            <p-button 
              icon="pi pi-save" 
              label="Guardar" 
              (click)="insertarCargo()">
            </p-button>
         </div>
      </div>
   </div>
   
  
  
  </div>
  <br />
</p-dialog>
<p-toast [style]="{ marginTop: '80px' }"></p-toast>
