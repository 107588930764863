import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Filtering} from '../../../../service/filtering/filtering';
import {BaseView} from '../../../base-view';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { environment } from 'src/environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { build, list, stack } from 'src/app/common/classes/request-builder';
import moment from 'moment';
import { createCsv } from 'src/app/common/createCsv';
import { Utils } from 'src/app/common/utils';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { SiexApiService } from 'src/app/service/api/siex-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaSiembraModel } from 'src/app/models/tareas/tarea-siembra.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-siembra',
    templateUrl: './siembra.component.html',
    styleUrls: ['./siembra.component.scss']
})

export class SiembraComponent extends BaseView<TareaSiembraModel> implements OnInit {
    public years = [
        {label: '...', value: 0},
    ];
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    public tipoAplicacion =  environment.features.applicationType;
    public serverUrl = environment.serverUrl;
    public appName = environment.appName;
    public selectedYears = 0;
    public modalShowCampaign  = false;
    public showCampaign = environment.features.showTareasSiembraCampaign;

    public isAdmin = (StorageManager.getUser().rol === 'admin');
    public rolUser = StorageManager.getUser().rol;

    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public clienteName = environment.features.clienteName;
    public fincaName = environment.features.fincaName;
    public sectorName = environment.features.sectorName;
    public parcelasName = environment.features.parcelasName;
    public showTareasSiembraProductos = environment.features.showTareasSiembraProductos;
    public showTareasSiembraMaquinarias = environment.features.showTareasSiembraMaquinarias;

    public parcelas = list();
    public cultivos = list();
    
    public filter: Filtering<TareaSiembraModel> = new Filtering<TareaSiembraModel>();
    public getRequest: RequestHandler<TareaSiembraModel[]> = this.tareasApi.siembra.GET;
    public deleteRequest: RequestHandler<TareaSiembraModel> = this.tareasApi.siembra.DELETE;
    public menuItemId = 'tareas-siembra';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: TareaSiembraModel = {};

    public formFields: ItemInterface<object> [] = [
        {
            field: 'cliente_nombre',
            name: this.clienteName,
            label: this.clienteName,
            inputType: {type: InputType.TEXT},
            visible: this.userCanSeeClientes
        },
        {
            field: 'check_previa',
            name: 'Tarea previa a la siembra',
            label: 'NO < Tarea previa a la siembra > SÍ',
            inputType: {
                type: InputType.RO_SWITCH
            }
        },
        {
            field: 'fecha_inicio',
            name: 'Fecha de inicio',
            label: 'Fecha de inicio',
            inputType: {type: InputType.TEXT},
            visible: environment.appName !== 'cartagenafresh'
        },
        {
            field: 'fecha_fin',
            name: environment.appName === 'cartagenafresh' ? 'Fecha Plantación' : 'Fecha de fin',
            label: environment.appName === 'cartagenafresh' ? 'Fecha Plantación' : 'Fecha de fin',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'hora_inicio', 
            name: 'Hora Inicio',
            label: 'Hora Inicio',
            inputType: {type: InputType.TIME}
        },
        {
            field: 'hora_fin',
            name: 'Hora Fin',
            label: 'Hora Fin', 
            inputType: {type: InputType.TIME}
        },          
        {
            field: 'horas_totales', 
            name: 'Horas',
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales 
        },
        {
            field: 'facturado', 
            name: 'Facturado',
            label: 'Facturado', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: environment.appName === 'agrosalvi' 
        },
        {
            field: 'factura_albaran',
            name: 'Factura/Albarán',
            label: 'Factura/Albarán', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName === 'agrosalvi'
        },
        {
            field: 'finca_nombre',
            name: this.fincaName,
            label: this.fincaName,
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'sector_nombre',
            name: this.sectorName,
            label: this.sectorName,
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'parcelas_nombres',
            name: this.parcelasName,
            label: this.parcelasName,
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'maquinaria', 
            name: 'Maquinaria',
            label: 'Maquinaria', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName === 'cartagenafresh'
        },
        {
            field: 'cultivos_nombres',
            name: 'Cultivos',
            label: 'Cultivos',
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'variedades_nombres',
            name: 'Variedades',
            label: 'Variedades',
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'cultivo_precedente',
            name: 'Cultivo precedente',
            label: 'Cultivo precedente',
            inputType: {
                type: InputType.TEXT
            },
            visible: environment.appName !== 'cartagenafresh'
        },
        {
            field: 'tipo_tratamiento',
            name: 'Tipo de tratamiento',
            label: 'Tipo de tratamiento',
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Semillas', 'Plantones', 'Cosecha'),
            visible: environment.appName === 'pyf'
        },
        {
            field: 'producto_nombre',
            name: 'Producto/Tratamiento',
            label: 'Producto/Tratamiento',
            inputType: {
                type: InputType.TEXT
            },
            visible: this.showTareasSiembraProductos // Prosanzcu
        },
        {
            field: 'id_producto',
            name: 'Producto/Tratamiento',
            label: 'Producto/Tratamiento',
            inputType: {
                type: InputType.TEXT
            },
            visible: !this.showTareasSiembraProductos && environment.appName !== 'cartagenafresh' // Armentia
        },
        {
            field: 'dosis_siembra',
            name: 'Dosis/Ha',
            label: 'Dosis/Ha',
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'tipo_riego',
            name: 'Tipo riego',
            label: 'Tipo riego',
            inputType: {
                type: InputType.TEXT
            },
            visible: environment.appName === 'cartagenafresh'            
        }, 
        {
            field: 'tipo_labor',
            name: 'Tipo de labor',
            label: 'Tipo de labor',
            inputType: {
                type: InputType.TEXT
            },
        },
        {
            field: 'semilla',
            name: 'Semilla',
            label: 'Semilla',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tipo_semilla',
            name: 'Tipo de semilla',
            label: 'Tipo de semilla',
            inputType: {type: InputType.TEXT},
            visible: environment.appName !== 'cartagenafresh'
        },
        {
            field: 'lote_semilla', 
            name: 'Lote Semilla', 
            label: 'Lote Semilla', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.appName === 'cartagenafresh'
        },
        {
            field: 'observaciones',
            name: 'Observaciones',
            label: 'Observaciones',
            inputType: {
                type: InputType.TEXTAREA
            }
        },
    ];

    public formFieldsViverogest: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombre',
            name: 'Personal Siembra',
            label: 'Personal Siembra',
            inputType: {type: InputType.TEXTAREA}
        },
        {
            field: 'id_finca',
            name: this.fincaName,
            label: this.fincaName,
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'id_sector',
            name: this.sectorName,
            label: this.sectorName,
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas_nombres',
            name: this.parcelasName,
            label: this.parcelasName,
            inputType: {type: InputType.TEXTAREA}
        },
        {
            field: '', 
            hasLabel: false, 
            inputType: {type: InputType.EMPTY}
        },
        {
            field: 'cantidad_semilla',
            name: 'Cantidad Semilla',
            label: 'Cantidad Semilla',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cantidad_semilla',
            name: 'Cantidad Semilla',
            label: 'Cantidad Semilla',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_siembra',
            name: 'Fecha Siembra',
            label: 'Fecha Siembra',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sustrato',
            name: 'Sustrato',
            label: 'Sustrato',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'color',
            name: 'Color',
            label: 'Color',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'formato',
            name: 'Formato',
            label: 'Formato',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'semana_siembra',
            name: 'Semana Siembra',
            label: 'Semana Siembra',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tamizado',
            name: 'Tamizado',
            label: 'Tamizado',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote',
            name: 'Lote',
            label: 'Lote',
            inputType: {type: InputType.TEXT}
        }, 
        {
            field: 'cantidad_bandejas',
            name: 'Cantidad Bandejas',
            label: 'Cantidad Bandejas',
            inputType: {type: InputType.TEXT}
        },        
        {
            field: 'camara',
            name: 'Cámara',
            label: 'Cámara',
            inputType: {
                type: InputType.TEXT
            }
        },
        {
            field: 'tratamiento',
            name: 'Tratamiento',
            label: 'Tratamiento',
            inputType: {type: InputType.TEXT}
        },
        {
            field: '',
            hasLabel: false,
            inputType: {type: InputType.EMPTY}
        },
        {
            field: 'completado',
            name: 'Completado',
            label: 'Completado',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_descarga',
            name: 'Fecha Descarga',
            label: 'Fecha Descarga',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'observaciones',
            name: 'Observaciones',
            label: 'Observaciones',
            inputType: {type: InputType.TEXTAREA}
        },
        {
            field: '',
            hasLabel: false,
            inputType: {type: InputType.EMPTY}
        },
        {
            field: 'punta',
            name: 'Punta',
            label: 'Punta',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'semana_entrega',
            name: 'Semana Entrega',
            label: 'Semana Entrega',
            inputType: {type: InputType.TEXT}
        },
        {
            field: '',
            hasLabel: false,
            inputType: {type: InputType.EMPTY}
        },
        {
            field: '',
            hasLabel: false,
            inputType: {type: InputType.EMPTY}
        },
        {
            field: 'porcentaje_germinacion',
            name: '% Germinación',
            label: '% Germinación',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'remesa',
            name: 'Remesa',
            label: 'Remesa',
            inputType: {type: InputType.TEXT}
        }
    ];

    public formFieldsMaquinarias: ItemInterface<object>[] = [
        {
            field: 'velocidad', 
            name: 'Velocidad (Km/h)', 
            label: 'Velocidad (Km/h)', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'velocidad_esponja', 
            name: 'Velocidad Esponja', 
            label: 'Velocidad Esponja', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {
            field: 'fecha_inicio',
            header: 'Inicio',
            filter: '',
            visible: environment.appName !== 'cartagenafresh'
        },
        {
            field: 'fecha_fin',
            header: environment.appName === 'cartagenafresh' ? 'Fecha Plantación' : 'Fin',
            filter: ''
        },        
        {
            field: 'cliente_nombre',
            header: this.clienteName,
            filter: '',
            visible: environment.features.applicationType !== 'agrogest' ? true : false,
        },
        {
            field: 'finca_nombre',
            header: this.fincaName,
            filter: ''
        },
        {
            field: 'sector_nombre',
            header: this.sectorName,
            filter: ''
        },
        {
            field: 'parcelas_nombres',
            header: this.parcelasName,
            filter: ''
        },
        {
            field: 'cultivos_nombres',
            header: 'Cultivo',
            filter: ''
        },
        {
            field: 'variedades_nombres',
            header: 'Variedad',
            filter: ''
        },
        {
            field: 'dosis_siembra',
            header: 'Dosis Kg/Ha',
            filter: ''
        },
        {
            field: 'cultivo_precedente',
            header: 'Cultivo precedente',
            filter: '',
            visible: environment.appName !== 'cartagenafresh'
        },
        {
            field: environment.appName === 'pyf' ? 'tipo_labor' : 'observaciones',
            header: environment.appName === 'pyf' ? 'Tipo de Labor' : 'Observaciones',
            filter: ''
        },
        { field: 'facturado', header: 'Facturado', filter: '', visible: environment.appName === 'agrosalvi'}

    ];
    
    public colsViverogest: ColInterface[] = [
        {
            field: 'fecha_siembra',
            header: 'Fecha Siembra',
            filter: ''
        },
        {
            field: 'fecha_descarga',
            header: 'Fecha Descarga',
            filter: ''
        },        
        {
            field: 'cliente_nombre',
            header: this.clienteName,
            filter: '',
            visible: environment.features.applicationType !== 'agrogest' ? true : false,
        },
        {
            field: 'finca_nombre',
            header: this.fincaName,
            filter: ''
        },
        {
            field: 'sector_nombre',
            header: this.sectorName,
            filter: ''
        },
        {
            field: 'parcelas_nombres',
            header: this.parcelasName,
            filter: ''
        },
        {
            field: 'cultivos_nombres',
            header: 'Cultivo',
            filter: ''
        },
        {
            field: 'variedades_nombres',
            header: 'Variedad',
            filter: ''
        },
        {
            field: 'cantidad_semilla',
            header: 'Cantidad Semilla',
            filter: ''
        },
        {
            field: 'semana_entrega',
            header: 'Semana Entrega',
            filter: ''
        },
        {
            field: 'no_esta',
            header: 'No Está',
            filter: ''
        }
    ];

    constructor(public tareasApi: TareasApiService,
        private fincasApi: FincasApiService,
        private siexApi: SiexApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.siembra.GET, tareasApi.siembra.DELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        if ( this.showCampaign ) {
            this.fillCampaign();
            this.filter.addFilter((copy: TreeNode<TareaSiembraModel>[] | void) => (copy as TreeNode<TareaSiembraModel>[]).filter(row => {
                if ( this.selectedYears === 0 ){
                    return true;
                } else {
                    return this.compareDates(row);
                }
            }));
        }
        stack([
            build(
                this.fincasApi.parcelasGET, 
                this.parcelas, ((it: ParcelasModel) => (it.variedad || 'Sin variedad') + ' (' + it.cultivo + ')'), 
                true
            ),
            build(this.siexApi.cultivosGET, this.cultivos, 'cultivo', true),
        ]).then(() => {
            this.filterVariedades();
            this.filter.filter();
            return;
        }).catch (e => {
            console.log('catch en stack: ' + e);
        }
        );
      
        this.filter.addFilter((data: TreeNode<TareaSiembraModel>[] | void) => 
            (data || []).filter((finca: TreeNode<TareaSiembraModel>) => finca && finca.data?.id));
        
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'tareas-crear-siembra']);
        }
    }

    public duplicate(data: TareaSiembraModel) {
        this.router.navigate(['dashboard', 'tareas-duplicar-siembra', data.id]);
    }

    public edit(data: TareaSiembraModel) {
        this.router.navigate(['dashboard', 'tareas-editar-siembra', data.id]);
    }

    public override show(modal: string, data: TareaSiembraModel) {
        super.show(modal, data);

        this.model.check_previa = [1, '1', true, 'true'].includes(this.model.check_previa ?? '');
    }

    modalCampaign() {
        this.modalShowCampaign = true;
    }

    closeModalShowCampaign() {
        this.modalShowCampaign = false;
    }

    selectedCampaign(){
        this.filter.filter();
    }

    compareDates( row: TreeNode<TareaSiembraModel> ) {
        const tareaSiembra = row as TareaSiembraModel;
        if ( tareaSiembra.fecha_inicio == null || tareaSiembra.fecha_inicio.length === 0 ) {
            return false;
        }
        
        const auxFecha = tareaSiembra.fecha_inicio.split('/');
        const nuevaFecha = moment(auxFecha[2] + '-' + auxFecha[1] + '-' + auxFecha[0] );
        const fechaComparar = moment('20' + (this.selectedYears).toString() + '-07' + '-01' );
        const fechaMax = moment('20' + (this.selectedYears + 1 ).toString() + '-06' + '-30' );

        if ( (nuevaFecha > fechaComparar) && (nuevaFecha < fechaMax) ){
            return true;
        } else {
            return false;
        }
    }
    
    /**
     *  Rellena el listado de campañas
     */
    fillCampaign () {
        const fechaActual = new Date().getFullYear();
        let fechaDosDigitos = 0;
        
        for (let index = 2020; index <= fechaActual; index++) {

            fechaDosDigitos = parseInt( (index.toString()).substring(2, 4) , 10);
            this.years.push({ label: index.toString() + '-' + (fechaDosDigitos + 1 ).toString(), value: fechaDosDigitos });
            
        }
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields
        ];

        const csvContent = createCsv(arrayFormFields, this.filter.value, 'observaciones');
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Tarea Siembra.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();      
    }

    public globalVisualTransform(value: string, col: ColInterface) {
       
        if (
            col.field === 'parcelas_nombres' || 
            col.field === 'cultivos_nombres' ||
            col.field === 'variedades_nombres') {
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }

        if (col.field === 'facturado') {
            switch (value) {
            case '0':
                return 'No';
            case '1':
                return 'Sí';
            default:
                return 'No';
            }
        }
        return value;
    }

    // PETICIÓN GET PARA GENERAR CUADERNO 
    public generarCuaderno() {
        if (!this.model.fecha_inicio || !this.model.fecha_fin) {
            alert('Debe especificar un intervalo de fechas.');
            return;
        }
        let idCultivo = '';

    
        idCultivo = (this.parcelas.selected == null) ? '' : this.parcelas.selected.id_cultivo;

        let cuadernoFields = '?id_cultivo=' + idCultivo;

        const interval = this.getDates(this.model.fecha_inicio, this.model.fecha_fin);
        const fechaFin = interval.fechaFin;
        const fechaInicio = interval.fechaInicio;
        const anyo2 = interval.anyo;
        cuadernoFields += '&fecha_fin=' + fechaFin +
    '&fecha_inicio=' + fechaInicio + '&anyo=' + anyo2;

        window.open((this.serverUrl + 'ws/informes/index.php' + cuadernoFields), '_blank');
    }

    public getDates(inicio: string | Date, fin: string | Date): {fechaInicio: string, fechaFin: string, anyo: string} {
        const fechaInicio = (inicio instanceof Date) ? Utils.formatDate(inicio) : inicio;
        const fechaFin = (fin instanceof Date) ? Utils.formatDate(fin) : fin;
  
        // Si no hay año se calcula con los años de la fecha de inicio y de fin (2017-2018)
        let anyo = fechaInicio.split('/')[2] ?? '';
        anyo = fechaInicio.split('/')[2] + '-' + fechaFin.split('/')[2];
        if (fechaInicio.split('/')[2] === fechaFin.split('/')[2]) { anyo = fechaInicio.split('/')[2] ?? ''; }
  
        return {fechaInicio, fechaFin, anyo};
    }

    public filterVariedades() {
        const idx = [];
        const repes: string[] = [];
        this.parcelas.values = this.parcelas.values.map(it => {
            if (it.value && !repes.includes(it.label + '_' + it.value.id_finca + '_' + it.value.id_sector)) {
                repes.push(it.label + '_' + it.value.id_finca + '_' + it.value.id_sector);
                idx.push(it);
                return it;
            }
            return null;
        }).filter(it => it);
    }

}