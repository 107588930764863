import { Component, HostListener, Output, EventEmitter } from '@angular/core';

import type { AfterViewInit } from '@angular/core';
import SignaturePad from 'signature_pad';

declare const signaturePad: new (arg0: HTMLElement, arg1: {
    backgroundColor: string; 
    penColor: string; 
    velocityFilterWeight: number; 
    minWidth: number; 
    maxWidth: number; 
    throttle: number; // max x milli seconds on event update, OBS! this introduces lag for event update
    minPointDistance: number;
}) => SignaturePad;

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {


    @Output() sendFile: EventEmitter<File> = new EventEmitter<File>();
    @Output() sendTempImg: EventEmitter<string> = new EventEmitter<string>();

    public canvasWidth = 0;
    public canvasHeight = 0;
    public canvas: HTMLElement | null = null;
    public visibleSignature = false;
    public available_signature = false;

    constructor(
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const size = window.innerWidth > 1000 ? 1000 : window.innerWidth;
        this.canvasHeight = (this.canvasWidth = size * 0.8) / 1.85;
    }


    
    ngAfterViewInit() {
        this.canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
        if (this.canvas instanceof HTMLCanvasElement) {
            this.initSignaturePad();
        } else {
            console.error('El canvas no se encuentra o no es un HTMLCanvasElement.');
        }
    }
    
      
      

    async saveSign() {
        const file = this.dataURLtoFile((this.canvas as HTMLCanvasElement).toDataURL(), 'signature.png');
    
        const img64 = await this.getBase64(file);

        if ( img64 != null ) {
            this.sendTempImg.emit(img64 as string);
        }

        this.sendFile.emit(file);
        alert('Firma almacenada temporalmente');
    }

    public scrollToTop(_event: string) {
        console.log(_event);
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }




    dataURLtoFile(dataurl: string, filename: string) {
 
        const arr = dataurl.split(',');
        const mime = arr[0]?.match(/:(.*?);/)?.[1];
        const bstr = atob(arr[1] ?? '');
        let n = bstr.length; 
        const u8arr = new Uint8Array(n);
        
        while (n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], filename, {type: mime ?? ''});
    }


    getBase64(file: Blob): Promise<string | ArrayBuffer | undefined | null> {
        return new Promise( resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = function () {
                //console.log(reader.result);
                resolve(reader.result);
            };
  
            reader.onerror = function () {
                resolve(null);
                //console.log('Error: ', error);
            };
        });
 
    }


    private initSignaturePad() {
        this.available_signature = true;
        if (this.canvas) {
            const pad = new signaturePad(this.canvas, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)',
                velocityFilterWeight: 0.7,
                minWidth: 0.5,
                maxWidth: 2.5,
                throttle: 16,
                minPointDistance: 3,
            });
          
            const clearButton = document.getElementById('clear');
            const showPointsToggle = document.getElementById('showPointsToggle');
          
            clearButton?.addEventListener('click', () => {
                pad.clear();
                this.sendFile.emit(new File([], ''));
            });
          
            showPointsToggle?.addEventListener('click', () => {
                showPointsToggle.classList.toggle('toggle');
            });
        }
    }

}
