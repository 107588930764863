import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GraficaManez } from 'src/app/view/main/7_tratamientos/trata-manez/grafica-manez.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class GraficasService {

    baseUrl = 'ws/tratamientos/charts/';

    constructor(
        private http: HttpClient
    ) {}


    getManezGraficas(
        id_tratamiento: string, 
        n_tratamiento: number, 
        id_maquinaria: string, 
        fecha_tratamiento?: string
    ): Observable<GraficaManez> {
        if ( fecha_tratamiento == null){
            return this.http.get<GraficaManez>(
                environment.serverUrl  + this.baseUrl + 'get_charts_data.php?id_tratamiento=' + id_tratamiento +
                '&idTreatment=' + n_tratamiento + 
                '&idMaquinaria=' + id_maquinaria
            );
        } else {
            return this.http.get<GraficaManez>(
                environment.serverUrl  + this.baseUrl + 'get_charts_data.php?id_tratamiento=' + id_tratamiento +
                '&idTreatment=' + n_tratamiento + 
                '&idMaquinaria=' + id_maquinaria + 
                '&fecha=' + fecha_tratamiento
            );
        }
    }

    getManezGraficasCalendar(imei: string, fecha_tratamiento?: string): Observable<GraficaManez> {
        return this.http.get<GraficaManez>(`${environment.serverUrl+this.baseUrl}get_charts_data_calendar.php?imei=${imei}&fecha=${fecha_tratamiento}`);
    }

}